import { useState } from "react";
import { Input } from 'antd';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import { BASE_URL } from '../../utils/constants';
import { Avatar, Menu, Tooltip } from '@mui/material';
import { useSelector } from "react-redux";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
  textPlaceholder = "Write comment",
}) => {
  const [anchorRefEmoji, setanchorRefEmoji] = useState(null);
  const openEmoji = Boolean(anchorRefEmoji);
  const handleClickEmoji = (event) => {
      setanchorRefEmoji(event.currentTarget);
  };
  const handleCloseEmoji = () => {
      setanchorRefEmoji(null);
  }; 
  const [text, setText] = useState(initialText);
  const user = useSelector(state => state.mainReducer.user)
  const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'

  const isTextareaDisabled = text.length === 0;
  const onSubmit = (event) => {
    console.log('event',event);
    event.preventDefault();
    if (event.keyCode === 13) {
      handleSubmit(text);
      setText("");
    } else {
      handleSubmit(text);
      setText("");
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <div>
        <div className='flex items-center mt-2 '>
                    <Input
                        placeholder="Viết bình luận..."
                        className='py-1 rounded-3xl'
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        prefix={
                            <Avatar alt="Remy Sharp"  src={user.avatar ?BASE_URL + `/api/files/upload`+ user.avatar:userAvt}
                            />
                        }

                        suffix={<div className='text-gray-main'>
                        <Tooltip title='Chọn biểu tượng cảm xúc'>
                            <TagFacesIcon onClick={handleClickEmoji} className='mr-2 cursor-pointer'/>
                        </Tooltip>
                        <Tooltip title='Chọn ảnh'>
                            <ImageIcon className='cursor-pointer'/>
                        </Tooltip>
                        <Tooltip title='Gửi'>
                          <button className="cursor-pointer comment-form-button" disabled={isTextareaDisabled}>
                            <SendIcon className='ml-3 text-blue-700 cursor-pointer'/>
                          </button>
                        </Tooltip>
                        </div>}
                    />

        </div>
      </div>
      {/* <button className="px-2 py-1 mt-1 text-white bg-blue-500 rounded-sm cursor-pointer comment-form-button" disabled={isTextareaDisabled}>
        {submitLabel}
      </button> */}
      {hasCancelButton && (
        <span
          className="font-medium text-red-600 transition-all cursor-pointer hover:underline"
          onClick={handleCancel}
        >
          Cancel
        </span>
      )}
      <Menu
                                id="basic-menu"
                                anchorEl={anchorRefEmoji}
                                open={openEmoji}
                                onClose={handleCloseEmoji}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{
                                    '& .MuiList-root ': {
                                        padding: 0,
                                    },
                                    mb: 2,
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Picker
                                    data={data}
                                    onEmojiSelect={(emojiObject) => {
                                      setText(text + emojiObject.native);
                                    }}
                                    locale="vi"
                                    theme="light"
                                />
                            </Menu>
    </form>
  );
};

export default CommentForm;
