import React from 'react';
import { Avatar } from '@mui/material';
import { BASE_URL } from '../../utils/constants';

const Messenger = ({onClick, avt, userName, content}) => {
    const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'

    return (
        <div onClick={onClick} className='mb-2 flex border-b-2 border-[#ccc] justify-between mx-1 cursor-pointer hover:bg-slate-100 rounded-lg px-3 py-2'>
            <div className='flex items-center'>
                <div>
                <Avatar alt="Remy Sharp" src={avt ?BASE_URL + `/api/files/upload`+ avt:userAvt} />
                </div>
                <div className='flex flex-col ml-3 '>
                    <h3 className='text-[050505] text-base'>{userName}</h3>
                    <p className='text-sm font-light text-[#303030]'>{content}</p>
                </div>

            </div>
            <div className='flex flex-col float-right'>
                <span className='font-light text-[14px] text-[#7c7c7c]'>Hôm nay 8:56</span>
                {/* <span className='w-[20px] h-[20px] bg-[#F24E1E] justify-center flex items-center rounded-full ml-auto text-white'>4</span> */}
            </div>
        </div>
    );
};

export default Messenger;