import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SignForm from "./auth/SignForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import InputHook from "../components/input/InputHook";
import * as ActionTypes from '../controllers/ActionType';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingContext } from "../context/loadingContext";

const schema = yup
  .object({
    fullName: yup.string().required("Please enter your fullname"),
    date: yup.string().required("Please enter your date of birth")
              .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
    email: yup
      .string()
      .email("Please enter your email address")
      .required("Please enter your email address"),
    password: yup
      .string()
      .min(8, "Your password must be at least 8 characters or greater")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        {
          message:
            "Your password must have at least 1 uppercase, 1 lowercase, 1 special character",
        }
      )
      .required("Please enter your password"),
   
  })
  .required();
const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isLoading, setIsLoading} = React.useContext(LoadingContext);
  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
    // setValue,
    // getValues,
    reset,
    register
    // watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmitHandler = (values) => {
    setIsLoading(true)
    const notify = ({message, status}) => {
      if (status === 'success') toast.success(message);
      if (status === 'error') toast.error(message);
      
    }; 
    if (!isValid) return;
    dispatch({type:ActionTypes.API_REGISTER,setIsLoading, payload:values, navigate, reset, notify})
  };

  return (
    <SignForm
      Children={
        <div className=" bg-transparent sign-form-ani border-white  border-none w-[500px] have-y-scroll overflow-y-auto h-auto overflow-x-auto scroll-smooth relative z-10 m-auto rounded-lg p-5 flex flex-col shadow-md ">
          <h2 className="mt-5 mb-3 text-4xl font-bold text-center text-white">
            Register
          </h2>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="">
              <div className="flex flex-col mb-2">
                <InputHook
                  name="fullName"
                  placeholder="Full name"
                  id="fullName"
                  control={control}
                  type="text"
                ></InputHook>
                {errors.fullName && (
                  <p className="text-sm text-red-500">
                    {errors.fullName.message}
                  </p>
                )}
              </div>
              <div className="flex flex-col mb-2">
                <InputHook
                  name="date"
                  placeholder="dd-mm-yyyy"
                  id="date"
                  defaultValue="2021-01-01"
                  control={control}
                  type="date"
                ></InputHook>
                 {errors.date && (
                  <p className="text-sm text-red-500">
                    {errors.date.message}
                  </p>
                )}
                
              </div>
              <div className="flex text-white mb-2 mt-[30px]">
                <h2 className="mr-3 ml-[10px] text-[#E8E8E8]">Giới tính: </h2>
                <div className="ml-3 text-[#E8E8E8]">
                  <input type="radio" value="MALE" defaultChecked {...register("genderType")} />
                   Male 
                </div>
                <div className="ml-3 text-[#E8E8E8]">
                  <input type="radio" value="FEMALE" {...register("genderType")} />
                   Female 
                </div>
                <div className="ml-3 text-[#E8E8E8]">
                   <input type="radio" value="DIFFERENT" {...register("genderType")} />
                   Different 
                </div>
             
               
              
              </div>
              {/* <div className="flex flex-col mb-2">
               
                <InputHook
                  name="userName"
                  placeholder="Username"
                  id="userName"
                  control={control}
                  type="text"
                ></InputHook>
                {errors.userName && (
                  <p className="text-sm text-red-500">
                    {errors.userName.message}
                  </p>
                )}
              </div> */}
              <div className="flex flex-col mb-2">
               
                <InputHook
                  name="email"
                  placeholder="Email"
                  id="email"
                  control={control}
                  type="email"
                ></InputHook>
                {errors.email && (
                  <p className="text-sm text-red-500">{errors.email.message}</p>
                )}
              </div>
              <div className="flex flex-col mb-2">
               
                <InputHook
                  name="password"
                  placeholder="Password"
                  id="password"
                  control={control}
                  type="password"
                ></InputHook>
                {errors.password && (
                  <p className="text-sm text-red-500">
                    {errors.password.message}
                  </p>
                )}
              </div>
              {/* <div className="flex flex-col mb-2">
             
                <InputHook
                  name="phoneNumber"
                  placeholder="Phone number"
                  id="phoneNumber"
                  control={control}
                  type="text"
                ></InputHook>
                {errors.phoneNumber && (
                  <p className="text-sm text-red-500">
                    {errors.phoneNumber.message}
                  </p>
                )}
              </div> */}
              
              <button
                className={`w-full p-4 continue-btn text-white rounded-lg mt-5 font-semibold ${
                  isSubmitting ? "opacity-50" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="w-5 h-5 mx-auto border-2 border-t-2 border-white rounded-full border-t-transparent animate-spin"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
          <span className="mt-2 text-xl text-center text-white">or</span>
     
          <div className="flex items-center justify-center pb-5 mt-5">
            <span className="text-sm text-white">
              Do you already have an account?
            </span>
            <Link to="../signin">
              <span className="items-center ml-2 text-xl text-[blue] font-medium hover:opacity-60">
                Login
              </span>
            </Link>
          </div>
          <ToastContainer />
        </div>
      }
      
    ></SignForm>
  );
};

export default SignUp;
