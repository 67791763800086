import React from 'react';
import {Link} from "react-router-dom"
import Avatar from '@mui/material/Avatar';
import GroupIcon from '@mui/icons-material/Group';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PagesIcon from '@mui/icons-material/Pages';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../utils/constants';

const MainLeft = () => {
    const user = useSelector(state => state.mainReducer.user)
    const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'

    return (
        <div className='w-[20%] flex pr-1 flex-col  pt-4 h-[100vh] bg-slate-100 fixed overflow-x-auto have-y-scroll'>
        <Link to={'personal'}>

            <div className='flex  items-center  py-2 pl-[42px] rounded-md cursor-pointer hover:bg-slate-200'>
                <div>
                    <Avatar alt="Remy Sharp" src={user.avatar ?BASE_URL + `/api/files/upload`+ user.avatar:userAvt} />
                </div>
                <h2 className='ml-3 text-[#07142e] text-base font-[500] flex-nowrap'>
                {user?.fullName}
                </h2>
            </div>
        </Link>
            <div className='flex flex-col mt-3 border-t-2 border-slate-300'>
            <h2 className='ml-[42px] text-gray-main text-base font-[500] mt-3'>MENU</h2>
            <Link to={'/friends'}>
                <div className='flex  py-4  pl-[46px] cursor-pointer ml-[41px] mr-[36px] rounded-lg hover:bg-slate-200 '>
                    <GroupIcon className='text-gray-main'/>
                    <span className='ml-5 text-gray-main text-base font-[500]'>Bạn bè</span>
                </div> 

            </Link>
                <div className='flex  py-4  pl-[46px] cursor-pointer ml-[41px] mr-[36px] rounded-lg hover:bg-slate-200'>
                    <Diversity3Icon className='text-blue-500'/>
                    <span className='ml-5 text-gray-main text-base font-[500]'>Nhóm</span>
                </div> 
                <div className='flex  py-4  pl-[46px] cursor-pointer ml-[41px] mr-[36px] rounded-lg hover:bg-slate-200'>
                    <PagesIcon className='text-red-500'/>
                    <span className='ml-5 text-gray-main text-base font-[500]'>Trang</span>
                </div> 
                <div className='flex  py-4  pl-[46px] cursor-pointer ml-[41px] mr-[36px] rounded-lg hover:bg-slate-200'>
                    <StorefrontIcon className='text-blue-500'/>
                    <span className='ml-5 text-gray-main text-base font-[500]'>Marketplace</span>
                </div> 
            </div>
        </div>
    );
};

export default MainLeft;