import React from 'react';
import { Spin } from 'antd';
import { LoadingContext } from '../../context/loadingContext';


const Loading = () => {
    const {isLoading } = React.useContext(LoadingContext);
    return (
        isLoading &&
                <div className='loading'>
                    {<Spin size="large" />}
                </div>
    );
};

export default Loading;