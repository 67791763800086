import React, { useEffect, useState } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Avatar } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import { Text, Image, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from '@mantine/dropzone';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../controllers/ActionType';
import Stack from '@mui/material/Stack';
import { BASE_URL } from '../../utils/constants';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import { LoadingContext } from '../../context/loadingContext';
import { Link } from 'react-router-dom';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    borderRadius: '8px',
  };
  
const HeaderPersonal = ({user,isFriend, statusFriend}) => {
    const userMe = useSelector(state => state.mainReducer.user)
    const {isLoading , setIsLoading} = React.useContext(LoadingContext);

    const [files, setFiles] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const handleAddAvata = () => {
        dispatch({type:ActionTypes.API_UPLOAD_AVATAR, payload:files})
        handleClose()
    }
    const isSource = statusFriend?.friendSource?.id === userMe?.id
    const isTarget = statusFriend?.friendTarget?.id === user?.id
    console.log('isSource', isSource);
    console.log('isTarget', isTarget);
    

    function UpdateAvatar() {
        const previews = files.map((file, index) => {
          const imageUrl = URL.createObjectURL(file);
          return (
            <div className='relative'>
            <Image
              key={index}
              src={imageUrl}
              imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
            />  
            </div>
          );
        });
      
        return (
          <div>
            <Dropzone accept={IMAGE_MIME_TYPE} onDrop={(filesDrop)=>{
              setFiles(files=>[...filesDrop])
            }}>
              <Text align="center">+ Tải hoặc kéo ảnh tại đây</Text>
            </Dropzone>
      
            <SimpleGrid
              cols={4}
              breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
              mt={previews.length > 0 ? 'xl' : 0}
            >
              {previews}
            </SimpleGrid>
          </div>
        );
      }
      const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'
      const getUser = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_USER, setIsLoading, payload: {}})
      }
      useEffect(() => {
        getUser()
      }, [])
    const handleAddFriend = () => {
        dispatch({type:ActionTypes.API_ADD_FRIEND, payload:{friendSource: userMe.id, friendTarget: user.id}})
    }
    const handleDelFriend = () => {
        dispatch({type:ActionTypes.API_DELETE_FRIEND, payload:{friendSource: userMe.id, friendTarget: user.id}})
    }
    console.log('statusFriend', statusFriend);
    return (
        <div className='relative p-3 personal-page'>
            <div className='relative'>
                <img className='personal-page-cover' alt='Cover photoo' src='https://images.unsplash.com/photo-1465146344425-f00d5f5c8f07?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80'/>
                <div className='absolute flex items-center px-3 py-1 bg-white shadow-2xl cursor-pointer hover:bg-slate-100 rounded-xl right-4 bottom-4'>
                    <AddAPhotoIcon/>
                    <span className='ml-3'>Thêm ảnh bìa</span>
                </div>
            </div>
            <div className='p-4 bg-white shadow-md personal-main'>
                <div className='relative'>
                    <img alt="Remy Sharp" className='personal-main-img' src={user.avatar ?BASE_URL + `/api/files/upload`+ user.avatar:userAvt} />
                   {isFriend ?null:
                        <div className='inline-block personal-main-add-photo'>
                        <AddAPhotoIcon onClick={handleOpen}  />  
                        </div>
                   }
                </div>
                <div>
                    <div className='flex items-center'>
                        <img alt='coin' className='w-5' src='https://wordpress.iqonic.design/product/wp/socialv/wp-content/uploads/2021/06/coin.svg'/>
                        <span className='ml-3 text-[#07142e]'>99800 Coins</span>
                    </div>
                    <div className='flex mt-3 '>
                        <div className='flex items-center flex-1'>
                        <img alt='coin' className='w-5' src='https://wordpress.iqonic.design/product/wp/socialv/wp-content/uploads/2022/07/gems.svg'/>
                        <span className='ml-3 text-[#07142e]'>9989 Diamonds</span>
                        </div>
                        <div className='flex justify-center flex-1'>
                            <span className='text-xl font-semibold text-[#07142e]'>{user?.fullName}</span>
                        </div>
                        <div className='flex justify-around flex-1'>
                            <div className='flex flex-col items-center'>
                                <span className='text-[#07142e] text'>1</span>
                                <span className='text-xs text-gray-main text'>Post</span>
                            </div>
                            <div className='flex flex-col items-center'>
                                <span className='text-[#07142e] text'>5</span>
                                <span className='text-xs text-gray-main text'>Comments</span>
                            </div>
                            <div className='flex flex-col items-center'>
                                <span className='text-[#07142e] text'>40.4k</span>
                                <span className='text-xs text-gray-main text'>Views</span>
                            </div>
                        </div>
                    </div>

                    <div className='flex mt-3'>
                        <div className='flex-1 icon-left-personal'>
                            <FacebookIcon className='mr-2 text-blue-500 cursor-pointer ' />
                            <InstagramIcon className='mr-2 text-orange-500 cursor-pointer' />
                            <CoronavirusIcon className='mr-2 text-green-500 cursor-pointer' />
                            <YouTubeIcon className='mr-2 text-red-500 cursor-pointer' />
                            <LinkedInIcon className='mr-2 text-blue-500 cursor-pointer' />
                        </div>
                        <div className='flex justify-center flex-1'>
                            <div className='flex flex-col '>
                                <span className='text-xs text-gray-main'><LocationOnIcon fontSize='14px'/> Los Angles Ca</span>
                                <span className='text-xs text-gray-main'><PublicIcon fontSize='14px'/> thegioisoikeo.com</span>
                            </div>
                        </div>
                        {isFriend ? 
                            <div className='flex items-center justify-center flex-1'>
                                {statusFriend.status === "ACTIVE" ?
                                        <Button variant="outlined" className='text-sm text-gray-main border-gray-main' style={{marginRight:'8px'}} startIcon={<PeopleIcon />}> Bạn bè</Button>

                                    :   statusFriend.status === "PENDING" ?
                                    <>
                                        {isSource ?
                                        <Button variant="outlined" onClick={handleDelFriend} className='text-sm text-gray-main border-gray-main' style={{marginRight:'8px'}}>Hủy lời mời</Button>
                                        :
                                        <>
                                            <Button variant="outlined" onClick={handleAddFriend} className='text-sm text-gray-main border-gray-main' style={{marginRight:'8px'}}>Chấp nhận</Button> 
                                            <Button variant="outlined" onClick={handleDelFriend} className='text-sm text-gray-main border-gray-main' style={{marginRight:'8px'}}>Xóa</Button>

                                        </>
                                        }

                                    </>

                                    :
                                    <Button variant="outlined" onClick={handleAddFriend} className='text-sm text-gray-main border-gray-main' style={{marginRight:'8px'}}>Kết bạn</Button>

                                    }
                                    <Link to={`/messenger/${user.id}`}>
                                        <Button variant="contained" className='text-sm text-white bg-blue-main' startIcon={<MessageIcon />}> Nhắn tin</Button>
                                    </Link>
                            </div>
                            :
                        
                            <div className='flex items-center justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer text-gray-main bg-slate-100 hover:bg-slate-200'>
                                <EditIcon className='text-[#2f65b9]'/>
                                <span className='ml-5 text-sm'>Chỉnh sửa trang cá nhân</span>
                            </div>
                        }
                      
                    </div>

                </div>
            </div>   
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                ClassName='border-none update-avatar'
            >
                <Box sx={style} className='p-4 outline-none'>

                    <Box className='flex items-center justify-between border-b-2'>
                            <Box></Box>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                            Cập nhật ảnh đại diện
                            </Typography>   
                            <CloseIcon onClick={handleClose} className='cursor-pointer'/>
                    </Box>
                    <Box className='mt-4'>
                        <UpdateAvatar/>
                    </Box>
                    <Box className='flex justify-end block pt-4 mt-4 text-right border-t-2'>
                    <Stack spacing={2} direction="row">
                        <Button variant="outlined">Hủy</Button>
                        <Button disabled={files.length >0 ? false: true} variant="contained" onClick={handleAddAvata}>Lưu</Button>
                    </Stack>
                    </Box>
                </Box>
      </Modal>
        </div>
    );
};

export default HeaderPersonal;