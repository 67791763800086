import React, { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Button } from 'antd';
import { Menu, Tooltip } from '@mui/material';
import { Input } from 'antd';
import CallIcon from '@mui/icons-material/Call';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MessageReceiver from './MessageReceiver';
import MessageSend from './MessageSend';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import AttachmentIcon from '@mui/icons-material/Attachment';

import TagFacesIcon from '@mui/icons-material/TagFaces';
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import { BASE_URL } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../controllers/ActionType'
import {getCurrentHouse,getCurrentHouseMessage, formatDate} from '../../controllers/generator'
import { SocketContext } from '../../config/SocketCustom';
import { LoadingContext } from '../../context/loadingContext';
import axios from 'axios';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'


const MessengerContent = ({userId, userConversationId,user}) => {
    const [anchorRefEmoji, setanchorRefEmoji] = useState(null);
    const openEmoji = Boolean(anchorRefEmoji);
    const handleClickEmoji = (event) => {
        setanchorRefEmoji(event.currentTarget);
    };
    const handleCloseEmoji = () => {
        setanchorRefEmoji(null);
    }; 
    const scrollRef = useRef(null);
    const [textMessage, setTextMessage] = React.useState('');
    const [message, setMessage] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);
    const {isLoading, setIsLoading} = React.useContext(LoadingContext);
    // const message = useSelector(state => state.mainReducer.message);
    const newMessage = useSelector(state => state?.mainReducer?.message);
    const token = useSelector(state => state.socialAuthReducer.token);
    const dispatch= useDispatch();
    const socketContext = useContext(SocketContext);
    const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'
    const handleSubmit = (e) => {
        e.preventDefault();
        socketContext.emit('messageSendTo', {message:textMessage, idConversation:userConversationId?.id, idUserSend:user?.id})
        setTextMessage('')
    }
    const scrollToBottom = () => {
        if (scrollRef.current) {
            const scrollContainer = scrollRef.current;
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
      }


      const handleScroll = () => {
        const scrollPosition = scrollRef.current.scrollTop;
        if (scrollPosition === 0 && message.length < totalElements) {
          console.log('Load more messages');
          fetchMessageMore();
        }
      };

      const fetchMessageMore = async () => {
        const {data} = await axios.get(`${BASE_URL}/api/v1/conversations/messages/${userConversationId.id}`,{headers: {Authorization: `Bearer ${token}`}, params: { offset: message.length }});
        setMessage((message) => [...data.content.reverse(), ...message]);
        setTotalElements(data.totalElements);
        setIsLoading(false)
       
     }
      const fetchMessage = async () => {
        const {data} = await axios.get(`${BASE_URL}/api/v1/conversations/messages/${userConversationId.id}`,{headers: {Authorization: `Bearer ${token}`}});
        console.log(data);
        setMessage(data.content.reverse());
        setTotalElements(data.totalElements);
        setIsLoading(false)
       
     }
    useEffect(() => {
        if(!newMessage||newMessage.length ===0 || userConversationId?.id !== newMessage[0] ) return;
        setMessage([...message, newMessage[1]])
        setTotalElements(totalElements+1)
    } ,[newMessage])
    useEffect(() => {
        setIsLoading(true)
         fetchMessage();

        // dispatch({type:ActionTypes.API_GET_MESSAGE, setMessage, setIsLoading, payload:userConversationId.id})
    }, [userConversationId.id])

    useEffect(() => {
        if(!message || message.length===0) return;
        scrollToBottom();
      }, [message]);
    return (
        <div className='flex-[2] bg-white rounded-xl shadown-message h-[80vh]'>
            <div  className='mb-2 flex border-b-2 border-[#ccc] items-center justify-between mx-4 cursor-pointerrounded-lg px-3 py-2'>
                <div className='flex items-center'>
                    <div>
                    <Avatar alt="Remy Sharp" src={userId?.avatar ?BASE_URL + `/api/files/upload`+ userId.avatar:userAvt} />
                    </div>
                    <div className='flex flex-col ml-3 '>
                        <h3 className='text-[050505] text-base'>{userId?.fullName}</h3>
                        <p className='text-sm font-light text-[#303030]'>online - last seen, 2.02 pm</p>
                    </div>

                </div>
                <div className='flex '>
                <Tooltip title='Bắt đầu gọi thoại'>
                    <CallIcon className='cursor-pointer text-[#9747ff]'/>
                </Tooltip>
                <Tooltip title='Bắt đầu gọi video'>
                    <VideoCallIcon className='ml-4 cursor-pointer text-[#9747ff]'/>
                </Tooltip>
                <Tooltip title='Tùy chọn'>
                    <MoreVertIcon className='ml-4 cursor-pointer text-[#9747ff]'/>
                </Tooltip>
                </div>
            </div>
            <div className='mt-6 pl-[28px] h-[70%] have-y-scroll overflow-y-auto' ref={scrollRef} onScroll={handleScroll}>
                <div>
                    {message?.map((item, index) => {
                        if (item?.user?.id === userId?.id) {
                            return <MessageReceiver key={index} avt={userId?.avatar ?BASE_URL + `/api/files/upload`+ userId.avatar:userAvt} content={item.message} time={formatDate(item.createdAt)} />
                        }
                        else {
                            return <MessageSend key={index} content={item.message} time={formatDate(item.createdAt)} />
                        }
                    })}

                </div>
           
            </div>
            <form onSubmit={handleSubmit}>
                <div className=' mt-[10px] p-5 flex items-center'>
                    <Input
                        placeholder="Enter your message"
                        className='py-2 rounded-3xl'
                        value={textMessage}
                        onChange={(e) => setTextMessage(e.target.value)}
                        prefix={<Tooltip title='Đính kèm file'>
                            <AttachmentIcon className="mr-4 cursor-pointer site-form-item-icon text-gray-main" />
                        </Tooltip>}

                        suffix={<div className='text-gray-main'>
                        <Tooltip title='Chọn biểu tượng cảm xúc'>
                            <TagFacesIcon onClick={handleClickEmoji} className='mr-2 cursor-pointer'/>
                            {/* <Picker data={data} onEmojiSelect={console.log} /> */}
                        </Tooltip>
                        <Tooltip title='Chọn ảnh'>
                            <ImageIcon className='cursor-pointer'/>
                        </Tooltip>
                        <Tooltip title='Gửi'>
                        <button type='submit'>
                            <SendIcon  className='ml-3 text-blue-700 cursor-pointer'/>
                        </button>
                        </Tooltip>

                        </div>}
                    />
                    <div className='w-[40px] h-[40px] ml-4 flex text-white rounded-xl bg-[#6e00ff] justify-center items-center'>
                        <KeyboardVoiceIcon/>
                    </div>
                </div>
            </form>
            <Menu
                                id="basic-menu"
                                anchorEl={anchorRefEmoji}
                                open={openEmoji}
                                onClose={handleCloseEmoji}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                sx={{
                                    '& .MuiList-root ': {
                                        padding: 0,
                                    },
                                    mb: 2,
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Picker
                                    data={data}
                                    onEmojiSelect={(emojiObject) => {
                                        setTextMessage(textMessage + emojiObject.native);
                                    }}
                                    locale="vi"
                                    theme="light"
                                />
                            </Menu>

        </div>
    );
};

export default MessengerContent;