import React from 'react';

import { Avatar } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
const FriendRequest = ({avt, friendName,friendManually}) => {
    return (
        <div className='flex p-3 rounded-lg cursor-pointer hover:bg-slate-200'>
             <Avatar alt="Remy Sharp" src={avt} />
                <div className='ml-4'>
                    <h3 className='font-semibold'>{friendName}</h3>
                    <span className='text-sm font-light text-gray-main'>{friendManually} bạn chung</span>
                    <Stack spacing={2} direction="row">
                        <Button variant="contained">Chấp nhận</Button>
                        <Button variant="outlined">Từ chối</Button>
                    </Stack>
                </div>

            </div>
    );
};

export default FriendRequest;