import React, { useState, useEffect, useContext } from "react";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { checkArray } from "../../controllers/generator";
import "./Comment.scss";
import { SocketContext } from "../../config/SocketCustom";
import {BASE_URL} from '../../utils/constants'
import * as ActionTypes from '../../controllers/ActionType'
import { LoadingContext } from "../../context/loadingContext";

const Comments = ({ commentURL, idPost }) => {
  const token = useSelector(state => state.socialAuthReducer.token)
  const comments = useSelector(state => state.mainReducer.comments);
  const {isLoading , setIsLoading} = React.useContext(LoadingContext);
  // const [comments, setComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);
  const user = useSelector(state => state.mainReducer.user)
  const dispatch = useDispatch();
  const socketContext = useContext(SocketContext);
  const id_User = user?.id;
  const id_Post = idPost;
  const loadComment = async () => {
    setIsLoading(true)
    dispatch({type: ActionTypes.API_GET_COMMENT,setIsLoading, payload:id_Post})
  }

  useEffect(() => {
    loadComment();
  }, [id_Post]);

  const addComment = async (content, id_ParentComment) => {
    if(id_ParentComment) {
      socketContext.emit("comment", {content: content, postId: id_Post, userId: id_User, parentId: id_ParentComment} );
    } else {
      socketContext.emit("comment", {content: content, postId: id_Post, userId: id_User} );
    }
  };
  const deleteComment = async (commentId) => {
    // if (window.confirm("Are you sure that you want to remove comment")) {
    //   await axios
    //     .delete(`${BASE_URL}/api/Comments/${commentId}`, {
    //       data: { id_User: id_User },
    //     })
    //     .then(() => {
    //       // loadComment();
    //     });
    // }
  };

  const updateComment = async (text, commentId) => {
    // await axios
    //   .put(`${BASE_URL}/api/Comments/${commentId}`, {
    //     id_User: id_User,
    //     content: text,
    //   })
    //   .then(() => {
    //     console.log("success");
    //     loadComment();
    //     setActiveComment(null);
    //   });
  };

  return (
    <div className="w-full h-auto p-1 mt-1 overflow-x-hidden overflow-y-hidden rounded-l ">
      <div className="w-full mx-auto comments">
      <CommentForm submitLabel="Write" handleSubmit={addComment} />
        <div className="p-3 overflow-y-auto comments-container have-y-scroll ">
          {checkArray(comments)&&comments.length > 0 &&
            comments.map((item) => (
              <Comment
                key={v4()}
                comment={item}
                id_Post={id_Post}
                addComment={addComment}
                currentUserId={id_User}
                deleteComment={deleteComment}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                updateComment={updateComment}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Comments;
