import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SignForm from "./auth/SignForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import InputHook from "../components/input/InputHook";
import * as ActionTypes from '../controllers/ActionType';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { LoadingContext } from "../context/loadingContext";

const schema = yup
  .object({
    // userName: yup.string().required("Please enter your username"),
    email: yup
    .string()
    .email("Please enter your email address")
    .required("Please enter your email address"),
    password: yup
      .string()
      .min(8, "Your password must be at least 8 characters or greater")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        {
          message:
            "Your password must have at least 1 uppercase, 1 lowercase, 1 special character",
        }
      )
      .required("Please enter your password"),
  })
  .required();
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isLoading, setIsLoading} = React.useContext(LoadingContext);
  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const onSubmitHandler = (values) => {
    setIsLoading(true)
    const notify = ({message, status}) => {
      if (status === 'success') toast.success(message);
      if (status === 'error') toast.error(message);
      
    }; 
    dispatch({type:ActionTypes.API_LOGIN_TOKEN, payload:values,setIsLoading, navigate, reset, notify})
    if (!isValid) return;

  };
  return (
    <SignForm
      Children={
        <div className=" w-[500px] sign-form-ani border-white border-none h-auto relative z-10 m-auto rounded-lg p-5 flex flex-col shadow-md ">
          <h2 className="mt-5 mb-3 text-4xl font-bold text-center text-white">
            Login
          </h2>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="">
              <div className="flex flex-col mb-2">
                <InputHook
                  name="email"
                  placeholder="Email"
                  id="email"
                  control={control}
                  type="email"
                ></InputHook>
                {errors.email && (
                  <p className="text-sm text-red-500">{errors.email.message}</p>
                )}
              </div>
              <div className="flex flex-col mb-2">
              
                <InputHook
                  name="password"
                  placeholder="Password"
                  id="password"
                  control={control}
                  type="password"
                ></InputHook>
                {errors.password && (
                  <p className="text-sm text-red-500">
                    {errors.password.message}
                  </p>
                )}
              </div>
              <button
                className={`w-full p-4 continue-btn text-white rounded-lg mt-5 font-semibold ${
                  isSubmitting ? "opacity-50" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="w-5 h-5 mx-auto border-2 border-t-2 border-white rounded-full button-sign border-t-transparent animate-spin"></div>
                ) : (
                  "Login"
                )}
              </button>
            </div>
          </form>
          <span className="mt-2 text-xl text-center text-white">or</span>
         
          <div className="mt-8 border border-1 border-slate-300"></div>
          <div className="flex items-center justify-center mt-8">
            <span className="ml-4 text-xl text-white cursor-pointer">
              Can't login?
            </span>
            <div className="w-[4px] h-[4px] rounded-full bg-slate-600 ml-4"></div>
            <Link to="../signup">
              <span className="ml-4 text-xl text-[blue] font-medium cursor-pointer hover:opacity-70">
                Sign up for account
              </span>
            </Link>
          </div>
          <ToastContainer />
        </div>
      }
    ></SignForm>
  );
};

export default SignIn;