import React from 'react';
import SchoolIcon from '@mui/icons-material/School';
import Post from '../bodyhome/Post';
import PostContainer from '../bodyhome/PostContainer';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import { useForm, } from "react-hook-form";
import { useSelector } from 'react-redux';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width:700,
  height:580 ,
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];
const PostsPersonal = () => {
  const dataPost = useSelector(state => state.mainReducer.post);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm();
  const onSubmit = data => {
    console.log(data);
    reset( {
        post:''
    })
  }

    return (
        <div className='w-full mx-auto flex space-x-5'>
        <div className='flex-1'>
            <div className='flex-[1] bg-white p-4 rounded-lg shadown-full-1'>
                <h2 className='font-semibold text-xl'>Giới thiệu</h2>
                <span className='btn-tieusu w-full flex justify-center uppercase rounded-md shadow-sm cursor-pointer transition-all hover:bg-slate-300 py-2 bg-slate-200' variant="contained">Thêm tiểu sử</span>
                <div className='flex items-center flex-wrap mt-3'>
                    <SchoolIcon className='text-gray-main'/>
                    <span className='ml-3'>Đã học tại <strong className='cursor-pointer'>THPT Phan Chu Trinh -  Đắk Lắk</strong></span>
                </div>
                <div className='flex items-center flex-wrap mt-3'>
                    <SchoolIcon className='text-gray-main'/>
                    <span className='ml-3'>Đã học tại <strong className='cursor-pointer'>THPT Phan Chu Trinh -  Đắk Lắk</strong></span>
                </div>
                <span onClick={handleOpen} className='btn-tieusu w-full flex justify-center uppercase rounded-md shadow-sm cursor-pointer transition-all hover:bg-slate-300 py-2 bg-slate-200 mt-2' variant="contained">Chỉnh sửa chi tiết</span>

                <span className='mt-3 btn-tieusu w-full flex justify-center uppercase rounded-md shadow-sm cursor-pointer transition-all hover:bg-slate-300 py-2 bg-slate-200' variant="contained">Thêm sở thích</span>

                
            </div>

            <div className='flex-[1] bg-white p-4 rounded-lg mt-4 shadown-full-1'>
                <h2 className='font-semibold text-xl'>Ảnh</h2>
                <ImageList sx={{ width: 400, height: 450 }} cols={3} rowHeight={164}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img}>
                        <img
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </ImageListItem>
      ))}
                </ImageList>
                
            </div>

        </div>
            <div className='flex-[2]  rounded-lg'>
                <Post/>
                {dataPost?.map((item, index) => {
                    const reaction =item?.reaction ? item.reaction : [];
                    const numberLike = reaction.reduce((total, itemReaction) => {
                        return total + itemReaction[1]
                    }, 0)
                    return (
                            <PostContainer
                                key={index}
                                idPost={item?.id} 
                                username={item?.userCreate?.fullname} 
                                time={item?.createdAt} content={item?.content}
                                avt ='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
                                image={item?.fileEntities}
                                scopeType={item?.scopeType}
                                numberlike={numberLike}
                                numbercomment={item?.totalComment ? item.totalComment: 0}
                                numbershare='100'
                                {...item}
                            />
                    )
                })}
            </div>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={handleClose}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={open}>
                   <Box sx={style} className='have-y-scroll overflow-y-auto'>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex justify-between items-center'>
                            <span></span>
                            <h3 className='text-[#050505] text-[20px] font-bold '>Chỉnh sửa chi tiết</h3>
                            <div onClick={handleClose} className='p-1 bg-[#e4e6eb] text-[#1c1e21] flex justify-center mb-2 rounded-full cursor-pointer hover:opacity-90'>
                              <CloseIcon className='text-[#1c1e21]'/>
                            </div>
                        </div>
                        <div className='under-line'></div>
                        <div className='my-2'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Chỉnh sửa phần giới thiệu</h4>
                            <span className='text-[#65676b] text-[15px]'>Chi tiết bạn chọn sẽ hiển thị công khai.</span>
                        </div>

                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Công việc</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("make")} />
                                  <span className='text-[#050505] text-[15px]'>Làm việc tại học sinh trường THPT</span>
                                </div>
                                <EditIcon/>
                            </div>
                            <div className='mt-2'> 
                              <AddCircleOutlineIcon className='text-[#1876f2] text-[12px]'/>
                              <span className='text-[#1876f2] text-[15px] ml-2'>Thêm nơi làm việc</span>
                            </div>
                        </div>

                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Học vấn</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("school")} />
                                  <span className='text-[#050505] text-[15px]'>Học CNTT - IT tại Trường Đại Học Nông Lâm TP.HCM</span>
                                </div>
                                <EditIcon/>
                            </div>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("school2")} />
                                  <span className='text-[#050505] text-[15px]'>Đã học tại THPT Phan Chu Trinh Eah'leo - Đắk Lắk</span>
                                </div>
                                <EditIcon/>
                            </div>
                            <div className='mt-2'> 
                              <AddCircleOutlineIcon className='text-[#1876f2] text-[12px]'/>
                              <span className='text-[#1876f2] text-[15px] ml-2'>Thêm trường trung học</span>
                            </div>
                            <div className='mt-2'> 
                              <AddCircleOutlineIcon className='text-[#1876f2] text-[12px]'/>
                              <span className='text-[#1876f2] text-[15px] ml-2'>Thêm trường cao đẳng đại học</span>
                            </div>
                        </div>

                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Tỉnh/Thành phố hiện tại</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("address")} />
                                  <span className='text-[#050505] text-[15px]'>Sống tại Buôn Ma Thuột, Đắc Lắk, Vietnam</span>
                                </div>
                                <EditIcon/>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Quê quán</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("addressOld")} />
                                  <span className='text-[#050505] text-[15px]'>Sống tại Buôn Ma Thuột, Đắc Lắk, Vietnam</span>
                                </div>
                                <EditIcon/>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Mối quan hệ</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("addressOld")} />
                                  <span className='text-[#050505] text-[15px]'>Độc thân</span>
                                </div>
                                <EditIcon/>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Tham gia facebook</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("addressOld")} />
                                  <span className='text-[#050505] text-[15px]'>Tham gia vào Tháng 1 năm 2017</span>
                                </div>
                                <EditIcon/>
                            </div>
                        </div>
                        <div className='mt-6'>
                            <h4 className='text-[#050505] text-[17px] font-medium'>Người theo dõi</h4>
                            <div className='flex items-center justify-between mt-2'>
                                <div>
                                  <Switch {...label} {...register("addressOld")} />
                                  <span className='text-[#050505] text-[15px]'>Có 92 người theo dõi</span>
                                </div>
                                <EditIcon/>
                            </div>
                        </div>
                        <div className='top-line'></div>

                        <div className='mt-4 flex justify-between items-center'>
                          <span className='text-[#1877f2]  text-[15px]'>Cập nhật thông tin</span>
                          <div>
                            <Button onClick={handleClose} variant="outlined" style={{marginRight:'12px'}}>Hủy</Button>
                            <Button variant="contained" type='submit'>Lưu</Button>
                          </div>
                        </div>
        
                        

                      </form>
                   </Box>
              </Fade>
            </Modal>
        </div>
    );
};

export default PostsPersonal;