import { Tooltip } from '@mui/material';
import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationItem from './NotificationItem';

const NotificationPri = () => {
    return (
        <div className='w-[370px]  px-4 my-3'>
            <div className='flex items-center justify-between '>
                <h2 className='text-base font-semibold'>Thông báo</h2>
                <div>
                <Tooltip title='Tùy chọn'>
                    <MoreHorizIcon className='cursor-pointer mr-3 text-gray-main' />
                </Tooltip>
                </div>

            </div>
            <div className='flex mt-3'>
                <h3 className='px-4 py-2 bg-blue-200 cursor-pointer hover:bg-slate-300  text-blue-700 rounded-3xl'>Tất cả</h3>
                <h3 className='px-4 py-2 cursor-pointer hover:bg-slate-300 rounded-3xl ml-3'>Chưa đọc</h3>
            </div>
            <div className='mt-3'>
                <h2 className='font-semibold text-base'>Mới</h2>
                <NotificationItem 
                 avt='https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                 content='Thằng kia đã gắn thẻ bạn trong một bình luận'
                />
              
            </div>
            <div className='mt-3'>
                <h2 className='font-semibold text-base'>Chưa đọc</h2>
                <NotificationItem 
                 avt='https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                 content='Thằng kia đã gắn thẻ bạn trong một bình luận'
                 isNotSeen

                />
               
            </div>
        </div>
    );
};

export default NotificationPri;