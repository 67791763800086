import React, { useContext } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Messenger from './Messenger';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SocketContext } from '../../config/SocketCustom';
import { LoadingContext } from '../../context/loadingContext';
import * as ActionTypes from '../../controllers/ActionType'

const MessengerLeft = ({userConversation, user, userConversationId}) => {
    const socketContext = useContext(SocketContext);
    const dispatch = useDispatch();
    const {isLoading, setIsLoading} = React.useContext(LoadingContext);
    const newMessage = useSelector(state => state?.mainReducer?.message);

    const handleMessage = (id) => {
        setIsLoading(true)
        dispatch({type:ActionTypes.API_GET_MESSAGE, setIsLoading, payload:id})
        dispatch({type: ActionTypes.API_GET_MESSENGER_ME,setIsLoading, payload: '', id: id});
        dispatch({ttype:'update',type:ActionTypes.UPDATE_GET_MESSAGE,payload:[]})
    }   

    return (
        <div className='flex-1 '>
            <Input size="large" className='py-3 rounded-2xl shadown-message' placeholder="Search" prefix={<SearchOutlined />} />
            <div className='p-4 mt-3 bg-white rounded-2xl shadown-message h-[70vh] have-y-scroll overflow-y-auto '>
                <h2 className='font-bold text-[#303030] text-[24px]'>Mọi người</h2>
                {userConversation.map((item, index) => {
                    const fullName = item?.users?.filter((item, index) => item?.id !== user?.id)[0]?.fullName;
                    const avt = item?.users?.filter((item, index) => item?.id !== user?.id)[0]?.avatar;
                    const id = item?.users?.filter((item, index) => item?.id !== user?.id)[0]?.id;
                    return (
                                <Messenger key={index}
                                    userName={fullName}
                                    onClick={()=> handleMessage(item.id)}
                                    avt={avt}
                                    content={item?.lastMessage?.message ? item.lastMessage.message: 'Không có tin nhắn nào'}
                                />
                    )
                })}
            </div>
        </div>
    );
};

export default MessengerLeft;