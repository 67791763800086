import React from 'react';
import { Avatar } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import SearchIcon from '@mui/icons-material/Search';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, message, Space } from 'antd';
import { BASE_URL } from '../../utils/constants';
const onClickInfo = ({ key }) => {
    message.info(`Click on item ${key}`);
  };
const onClickTC = ({ key }) => {
    message.info(`Click on item ${key}`);
  };


  const itemsInfo = [
    {
      label: '1st menu item',
      key: '1',
    },
    {
      label: '2nd menu item',
      key: '2',
    },
    {
      label: '3rd menu item',
      key: '3',
    },
  ];
  const itemsTC = [
    {
      label: 'Đổi chủ đề',
      key: '1',
    },
    {
      label: 'Thay đổi biểu tượng cảm xúc',
      key: '2',
    },
    {
      label: 'Chỉnh sửa biệt danh',
      key: '3',
    },
    {
      label: 'Tìm kiếm trong cuộc trò chuyện',
      key: '4',
    },
  ];

const MessengerInfo = ({userId}) => {
    const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'

    return (
        <div className='flex-1 bg-white rounded-xl shadown-message h-[80vh] '>
            <div className='flex flex-col items-center justify-center mt-3'>
             <Avatar style={{width:'90px', height:'90px'}} alt="Remy Sharp" className='w-[60px]' src={userId?.avatar ?BASE_URL + `/api/files/upload`+ userId.avatar:userAvt} />
             <span className='mt-2 text-base font-medium'>{userId?.fullName}</span>
            </div>

            <div className='flex justify-center mt-4'>
                <div className='flex flex-col items-center'>
                    <div className='w-[32px] h-[32px] hover:bg-slate-200 bg-slate-100 flex justify-center items-center rounded-full cursor-pointer'><FacebookIcon /></div>
                    <span className='text-[10px] text-gray-main'>Trang cá nhân</span>
                </div>
                <div className='flex flex-col items-center mx-2'>
                    <div className='w-[32px] h-[32px] hover:bg-slate-200 bg-slate-100 flex justify-center items-center rounded-full cursor-pointer'><CircleNotificationsIcon /></div>
                    <span className='text-[10px] text-gray-main'>Tắt thông báo</span>
                </div>
                <div className='flex flex-col items-center'>
                    <div className='w-[32px] h-[32px] hover:bg-slate-200 bg-slate-100 flex justify-center items-center rounded-full cursor-pointer'><SearchIcon /></div>
                    <span className='text-[10px] text-gray-main'>Tìm kiếm</span>
                </div>

            </div>
            <div className='p-2 mx-3 mt-4 hover:bg-slate-100'>
                <Dropdown
                    menu={{
                    items:itemsInfo,
                    onClick:onClickInfo
                    }}
                >
                    <Space className='flex justify-between text-base font-light cursor-pointer'>
                        Thông tin về đoạn chat
                        <DownOutlined className='ml-auto' />
                    </Space>
                </Dropdown>
            </div>
            <div className='p-2 mx-3 mt-4 hover:bg-slate-100'>
                <Dropdown
                    menu={{
                    items:itemsTC,
                    onClick:onClickTC
                    }}
                >
                    <Space className='flex justify-between text-base font-light cursor-pointer'>
                        Tùy chỉnh về đoạn chat
                        <DownOutlined className='ml-auto' />
                    </Space>
                </Dropdown>
            </div>
            <div className='p-2 mx-3 mt-4 hover:bg-slate-100'>
                <Dropdown
                    menu={{
                    items:itemsTC,
                    onClick:onClickTC
                    }}
                >
                    <Space className='flex justify-between text-base font-light cursor-pointer'>
                        File phương tiện, file và liên kết
                        <DownOutlined className='ml-auto' />
                    </Space>
                </Dropdown>
            </div>
            <div className='p-2 mx-3 mt-4 hover:bg-slate-100'>
                <Dropdown
                    menu={{
                    items:itemsTC,
                    onClick:onClickTC
                    }}
                >
                    <Space className='flex justify-between text-base font-light cursor-pointer'>
                        Quyền riêng tư & hỗ trợ
                        <DownOutlined className='ml-auto' />
                    </Space>
                </Dropdown>
            </div>
            
        </div>
    );
};

export default MessengerInfo;