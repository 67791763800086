import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducerMaster';
import rootSaga from './watcherMaster';
import { persistReducer } from 'redux-persist';
import localForage from 'localforage';

const rootPersistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [
        'socialAuthReducer',
        // 'TomHrBaseStoreReducer'
    ],
};

const reducers = persistReducer(rootPersistConfig, rootReducer)
const saga = createSagaMiddleware();
const middleWares = [saga];
const   store = createStore(
    reducers,
    applyMiddleware(...middleWares)
);

saga.run(rootSaga);

export default store;