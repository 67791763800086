import { TimeToLeaveTwoTone } from '@mui/icons-material';
import {BASE_URL} from '../utils/constants'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack';
import dayjs from 'dayjs';

function  encryptPayload(payload) {
    console.log('payload khong co', payload)
}

export function sendRequestToBE(data) {
    const { method, donotEncrypt, func, payload = {} } = data;
    return Promise.all([
        donotEncrypt ? payload : encryptPayload(payload)
    ]).then (([encodedPayload]) => {
        if (!func) {
            throw { code: 1, message: "Chưa cung cấp API muốn request" }
        }
    
        return axios({
            method:  method ||'POST',
            url: `${BASE_URL}/api/v1${func}`,
            data:   encodedPayload 
        })
    }).then((response) => {
        if(response.status === 200) {
            if(response.data && response.data.token) {
                return response.data.token
            } else {
                return {
                    code: response.data.code,
                    message: response.errors.errorMessage,
                }
            }

        } else {
            return { code: 1, message: "Hệ thống phản hồi status != 200 (1)" }
        }
    } ).catch(error => {
        if (error.response) {
            return { code: 1, message: "Hệ thống phản hồi status != 200 (2)" }
        } else if (error.request) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    })


}
export function sendRequestToBEMain(data) {
    const { method,token, donotEncrypt, func, payload = {} } = data;
    return Promise.all([
        donotEncrypt ? payload : encryptPayload(payload)
    ]).then (([encodedPayload]) => {
        if (!func) {
            throw { code: 1, message: "Chưa cung cấp API muốn request" }
        }
      
        return axios({
            method:  method ||'POST',
            headers: {
                authorization: token ? `Bearer ${token}` : undefined,
            },
            url: `${BASE_URL}/api/v1${func}`,
            data:   encodedPayload 
        })
    }).then((response) => {
        console.log('response', response, func)
        if(response.data ) {
            return response.data
        } else {
            return {
                code: response.data.code,
                message: response.errors.errorMessage,
            }
        }
    } ).catch(error => {
        if (error.response) {
            return { code: 1, message: error.response.data.message, errors: error.response.data.errors }
        } else if (error.request) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    })


}

export const getCurrentHouse = (time) => {
    // console.log('object', new Date(...time));
    const newTime = new Date(...time)
    const unixTimeZero = Date.parse(newTime);
    console.log('unixTimeZero', unixTimeZero);
    const t = new Date(unixTimeZero);
    console.log('t', t);
    time =
        t.getFullYear() +
        '-' +
        (t.getMonth() + 1) +
        '-' +
        t.getDate() +
        ' ' +
        t.getHours() +
        ':' +
        t.getMinutes() +
        ':' +
        t.getSeconds();
        console.log('object', time);
    var date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')),
        diff = (new Date().getTime() - date.getTime()) / 1000,
        day_diff = Math.floor(diff / 86400);
    var year = date.getFullYear(),
        month = date.getMonth() + 1,
        day = date.getDate();
    console.log('day_diff', day_diff);
    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
        return (
            year.toString() +
            '-' +
            (month < 10 ? '0' + month.toString() : month.toString()) +
            '-' +
            (day < 10 ? '0' + day.toString() : day.toString())
        );

    const r =
        (day_diff == 0 &&
            ((diff < 60 && 'Vài giây trước') ||
                (diff < 120 && '1 phút trước') ||
                (diff < 3600 && Math.floor(diff / 60) + ' phút trước') ||
                (diff < 7200 && '1 giờ trước') ||
                (diff < 86400 && Math.floor(diff / 3600) + ' giờ trước'))) ||
        (day_diff == 1 && 'Hôm qua') ||
        (day_diff < 7 && day_diff + ' ngày trước') ||
        (day_diff < 31 && Math.ceil(day_diff / 7) + ' tuần trước');
    return r;
};

export function getCurrentHouseMessage(dateString) {
    const now = dayjs();
    const newTime = new Date(...dateString)
    const date = dayjs(newTime);
    console.log(date);
    const diff = now.diff(date, 'minute');
    if (diff < 1) {
        return 'Vài giây trước';
    } else if (diff < 60) {
        return `${diff} phút trước`;
    } else if (now.year() === date.year()) {
        if (now.isSame(date, 'day')) {
            return date.format('HH:mm');
        } else {
            return date.format('DD/MM HH:mm:ss');
        }
    } else {
        return date.format('DD/MM/YYYY HH:mm:ss');
    }
}
const formatDate = (dateArray) => {
    const now = dayjs();
    const [year, month, day, hour, minute, second] = dateArray;
    const date = dayjs(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
    const diffMinutes = now.diff(date, 'minute');
    const diffSeconds = now.diff(date, 'second');
  
    if (diffMinutes < 1) {
      return 'Vài giây trước';
    } else if (diffMinutes < 60) {
      return `${diffMinutes} phút trước`;
    } else if (diffSeconds < 3600) {
      const diffHours = Math.floor(diffSeconds / 3600);
      return `${diffHours} giờ ${Math.floor((diffSeconds % 3600) / 60)} phút trước`;
    } else if (now.isSame(date, 'day')) {
      return date.format('HH:mm');
    } else if (now.isSame(date, 'year')) {
      return date.format('DD/MM HH:mm:ss');
    } else {
      return date.format('DD/MM/YYYY HH:mm:ss');
    }
  };


// Kiểm tra Object
const checkObject = (isObject) => {
    if (isObject && typeof isObject === 'object' && !Array.isArray(isObject)) {
        return true;
    } else {
        return false;
    }
}
// kiem tra mang
const checkArray = (isArray) => {
    if (isArray && Array.isArray(isArray)) {
        return true;
    } else {
        return false;
    }
}


  
 

export {
    checkObject,
    checkArray,
    formatDate
}