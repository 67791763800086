import * as ActionType from '../controllers/ActionType';

const initialState = {
    token:''
}

export default (state = initialState, action) => {
    switch (action.type) {
      case ActionType.UPDATE_LOGIN_TOKEN: {
           return {
                ...state, token: action.payload
           }
        }
        default:
            return state
    }
}