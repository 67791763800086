import React from 'react';
import { Avatar } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Friend from './Friend';
import FriendRequest from './FriendRequest';
import { Link } from 'react-router-dom';




const FriendHome = () => {
    return (
        <div className='fixed overflow-y-auto h-[50vh] right-0 z-10 pb-[40px] w-[390px] bg-slate-100 have-y-scroll'>
            <div className='flex items-center justify-between'>
             <span className='text-gray-main'>Lời mời kết bạn</span>   
             <Link to='/friends'> 
                <h3 className='px-3 py-1 text-blue-500 rounded-lg cursor-pointer hover:bg-slate-200'>Xem tất cả</h3>
             </Link>
            </div>
            <FriendRequest 
                avt='https://images.unsplash.com/photo-1608889175123-8ee362201f81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjR8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                friendName='Thảo Nguyên'
                friendManually='69' 
            />
            <div className='w-full h-[2px] bg-slate-300 my-3'></div>
            <div>
                <div className='flex items-center justify-between'>
                    <h3 className='text-gray-main'>Người liên hệ</h3>
                    <div>
                        <Tooltip title="Cuộc gọi mới" arrow>
                        <VideoCameraFrontIcon className='cursor-pointer text-gray-main'/>
                        </Tooltip>
                        <Tooltip title="Tìm kiếm theo tên hoặc nhóm" arrow>
                        <SearchIcon className='ml-3 cursor-pointer text-gray-main'/>
                        </Tooltip>
                        <Tooltip title="Tùy chọn" arrow>
                        <MoreHorizIcon className='ml-3 cursor-pointer text-gray-main'/>
                        </Tooltip>
                    </div>
                </div>
                <Friend 
                    avt='https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                    name='Lê Thẩm Dương'
               />
                <Friend 
                    avt='https://ss-images.saostar.vn/pc/1620357997360/saostar-dr2k3q7e7po6g9ry.jpg'
                    name='Sơn Tùng MTP'
               />
                <Friend 
                    avt='https://i.pinimg.com/474x/a2/eb/f5/a2ebf5b34c845bdd77fd2db56f42aed2.jpg'
                    name='G-Dragon'
               />
                <Friend 
                    avt='https://i.pinimg.com/736x/a2/a8/b6/a2a8b60e81f2710c05ccff8aee0ec16a.jpg'
                    name='Rose'
               />
                <Friend 
                    avt='https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                    name='Lê Thẩm Dương'
               />
                <Friend 
                    avt='https://ss-images.saostar.vn/pc/1620357997360/saostar-dr2k3q7e7po6g9ry.jpg'
                    name='Sơn Tùng MTP'
               />
                <Friend 
                    avt='https://i.pinimg.com/474x/a2/eb/f5/a2ebf5b34c845bdd77fd2db56f42aed2.jpg'
                    name='G-Dragon'
               />
                <Friend 
                    avt='https://i.pinimg.com/736x/a2/a8/b6/a2a8b60e81f2710c05ccff8aee0ec16a.jpg'
                    name='Rose'
               />
                <Friend 
                    avt='https://images.unsplash.com/photo-1628157588553-5eeea00af15c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'
                    name='Lê Thẩm Dương'
               />
                <Friend 
                    avt='https://ss-images.saostar.vn/pc/1620357997360/saostar-dr2k3q7e7po6g9ry.jpg'
                    name='Sơn Tùng MTP'
               />
                <Friend 
                    avt='https://i.pinimg.com/474x/a2/eb/f5/a2ebf5b34c845bdd77fd2db56f42aed2.jpg'
                    name='G-Dragon'
               />
                <Friend 
                    avt='https://i.pinimg.com/736x/a2/a8/b6/a2a8b60e81f2710c05ccff8aee0ec16a.jpg'
                    name='Rose'
               />
         
            </div>

        </div>
    );
};

export default FriendHome;