import * as ActionType from '../controllers/ActionType';

const initialState = {
    post:[],
    user: {},
    userId:{},
    statusFriend:{},
    comments: [],
    commentsChild: [],
    userConversation: [],
    userConversationId: {},
    message: [],
    friendRequest: [],
    isShow:{reply: false, edit: false, delete: false}
}

export default (state = initialState, action) => {
    switch (action.type) {
      case ActionType.UPDATE_GET_POST_SUCCESS: {
       
           return {
                ...state, post:[...action.payload]  
           }
        }
      case ActionType.UPDATE_GET_USER_SUCCESS: {
           return {
                ...state, user:{...action.payload}  
           }
        }
      case ActionType.UPDATE_COMMENT_SUCCESS: {
           return {
                ...state, comments:[...action.payload]  
           }
        }
      case ActionType.UPDATE_COMMENT_CHILD_SUCCESS: {
           return {
                ...state, commentsChild:[...action.payload]  
           }
        }
      case ActionType.UPDATE_GET_USER_ID_SUCCESS: {
           return {
                ...state, userId:{...action.payload}  
           }
        }
      case ActionType.UPDATE_STATUS_FRIEND_SUCCESS: {
          
           return {
                ...state, statusFriend:{...action.payload}  
           }
        }
          case ActionType.UPDATE_GET_MESSENGER_ME_SUCCESS: {
               return {
                    ...state, userConversation:[...action.payload]
               }
               }
          case ActionType.UPDATE_GET_MESSENGER_USER_SUCCESS: {
               return {
                    ...state, userConversationId:{...action.payload}
               }
               }
          case ActionType.UPDATE_GET_MESSAGE_SUCCESS: {
               return {
                    ...state, message:[...action.payload]
               }
               }
          case ActionType.UPDATE_GET_LIST_FRIEND_REQUEST_SUCCESS: {
               return {
                    ...state, friendRequest:[...action.payload]
               }
               }

        default:
            return state
    }
}