import React, { useEffect } from 'react';
import MessengerContent from './MessengerContent';
import MessengerInfo from './MessengerInfo';
import MessengerLeft from './MessengerLeft';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../controllers/ActionType';
import { LoadingContext } from '../../context/loadingContext';
import { useParams } from 'react-router-dom';

const MessengerBody = () => {
    const dispatch = useDispatch();
    const {isLoading, setIsLoading} = React.useContext(LoadingContext);
    const userConversation = useSelector(state => state.mainReducer.userConversation);
    const userConversationId = useSelector(state => state.mainReducer.userConversationId);
    const user = useSelector(state => state.mainReducer.user);

    const userId = userConversationId?.users?.filter((item, index) => item?.id !== user?.id)[0];

    const getUser = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_USER, setIsLoading, payload: {}})
      }
    useEffect(() => {
        getUser();
    }, [])
    useEffect(() => {
        if(!userConversationId) return;
      
        setIsLoading(true);
        dispatch({type: ActionTypes.API_GET_MESSENGER_ME,setIsLoading, payload: ''});
    },[])
    return (
        <div className='mt-[60px] p-6 pl-[45px] flex space-x-4 overflow-y-hidden bg-slate-100'>
             <MessengerLeft userConversationId={userConversationId} userConversation={userConversation} user={user} />
             <MessengerContent userConversationId={userConversationId}   userId={userId} user={user} />
             <MessengerInfo userId={userId} />
        </div>
    );
};

export default MessengerBody;