import React, { useEffect } from 'react';
import FriendItem from './FriendItem';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../controllers/ActionType'
import { Link } from 'react-router-dom';
import { LoadingContext } from '../../context/loadingContext';


const FriendRequest = () => {
    const dispatch = useDispatch();
    const friendRequest = useSelector(state => state.mainReducer.friendRequest);
    const {isLoading , setIsLoading} = React.useContext(LoadingContext);

    const getFriend = () => {
        dispatch({type:ActionTypes.API_GET_LIST_FRIEND_REQUEST,payload:{}});
    }
    const getUser = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_USER, setIsLoading, payload: {}})
      }
     useEffect(() => {
        getFriend();
        getUser();
    }, [])
    return (
        <div className=''>
            <div className='flex items-center justify-between'>
            <h2 className='text-xl font-semibold'>Lời mời kết bạn</h2>
            <span className='text-blue-500 cursor-pointer hover:underline '>Xem tất cả</span>
            </div>
            <div className='flex mt-3  flex-wrap w-full bg-white shadown-message max-h-[660px] have-y-scroll overflow-y-auto'>
                {friendRequest?.map((item,index) => {
                    return (
                            <FriendItem id={item?.id} key={index} request name={item?.fullName} avt={item?.avatar}/>
                    )
                })}
                {friendRequest?.length === 0 && <div className='flex items-center justify-center w-full p-4'>Không có lời yêu cầu kết bạn nào...</div>}
            </div>
        </div>
    );
};

export default FriendRequest;