import React from 'react';
import './Error.scss';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Link to='/'><Button type="primary" className='bg-blue-500 text-white'>Back Home</Button></Link>}
     />
    );
};

export default Error;