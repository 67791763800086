import React, { createContext, useEffect } from 'react'
import  io , { Socket } from 'socket.io-client';
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from 'react-redux';
import { SOCKET_URL } from '../utils/constants';
import * as ActionTypes from '../controllers/ActionType'
export const SocketContext = createContext();

function SocketCustom({children}) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.mainReducer.user)
    const token = useSelector(state => state.socialAuthReducer.token)

    const [socketClient, setSocketClient] = React.useState(null);

    useEffect(() => {
        if (user) {
                        const socket = io(SOCKET_URL, {
                            transports: ["websocket"],
                            allowCredentials: true,
                            query: {
                                token: token,
                            }

                        });
                        setSocketClient(socket);
            socket.on('connect', () => {
                socket.on('validateError',(data) => {
                    enqueueSnackbar(data, {variant: "error",});
                });
                socket.on('newPost',(data) => {
                  console.log('newPost',data);
                  dispatch({ttype:'update',type:ActionTypes.UPDATE_GET_POST,payload:data})
                }); // emit an event to the socket
                socket.on('comment',(data) => {
                    console.log("data comment",data);
                  dispatch({ttype:'update',type:ActionTypes.UPDATE_COMMENT,payload:data})
                }); // emit an event to the socket
                socket.on('createReaction',(data) => {
                  console.log('data createReaction',data);
                  dispatch({ttype:'updateReaction',type:ActionTypes.UPDATE_GET_POST,payload:data})
                }); // emit an event to the socket
                socket.on('deleteReaction',(data) => {
                  console.log("data deleteReaction",data);
                  dispatch({ttype:'updateReactionDel',type:ActionTypes.UPDATE_GET_POST,payload:data})
                }); // emit an event to the socket
                socket.on('friend',(data) => {
                  console.log("data friend",data);
                   dispatch({ttype:'update',type:ActionTypes.UPDATE_GET_USER_ID,payload:data})
                }); // emit an event to the socket
                socket.on('deleteFriend',(data) => {
                  console.log("data friend",data);
                   dispatch({ttype:'update',type:ActionTypes.UPDATE_GET_USER_ID,payload:data})
                }); // emit an event to the socket
                socket.on('message',(data) => {
                  console.log("message data",data);
                  dispatch({ttype:'update',type:ActionTypes.UPDATE_GET_MESSAGE,payload:data})
                  //  dispatch({ttype:'update',type:ActionTypes.UPDATE_GET_USER_ID,payload:data})
                }); // emit an event to the socket
            });
            return () => {
                socket.disconnect();
                setSocketClient(null);
            };
        } else {
            enqueueSnackbar("Vui lòng đăng nhập để sử dụng chức năng này", {
                variant: "error",
            });
        }

    }, [user]);
  return (
    <SocketContext.Provider value={socketClient}>
        {children}
    </SocketContext.Provider>
  )
}

export default SocketCustom