import React, { useEffect } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.scss'
import { Provider, useSelector } from 'react-redux';
import Main from './components/layouts/Main';
import ErrorPage from './pages/Error';
import Home from'./pages/Home'
import MessagePage from './pages/MessagePage';
import Personal from './pages/Personal';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Watch from './pages/Watch';
import store from './store';
import Friend from './components/friend/Friend';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';
import SocketCustom from './config/SocketCustom';
import Loading from './components/loading/Loading';
import { LoadingContextProvider } from './context/loadingContext';

const persistor = persistStore(store);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement:<ErrorPage/>,
    children:[
      {path:"/",element:<Home/>},
      {path:"/friends",element:<Friend/>},
      {path:"watch",element:<Watch/>}
    ]
  },
  {
    path: "signin",
    element: <SignIn />,
    errorElement:<ErrorPage/>,
  },
  {
    path: "signup",
    element: <SignUp />,
    errorElement:<ErrorPage/>,
  },
  {
    path: "personal",
    element: <Personal />,
    errorElement:<ErrorPage/>,
  },
  {
    path: "personal/:id",
    element: <Personal />,
    errorElement:<ErrorPage/>,
  },
  {
    path: "messenger",
    element: <MessagePage />,
    errorElement:<ErrorPage/>,
  },
  {
    path: "messenger/:id",
    element: <MessagePage />,
    errorElement:<ErrorPage/>,
  },
 

]);
function App() {
 
  return (
    <Provider store={store}>
    <SnackbarProvider>
      <PersistGate loading={null} persistor={persistor}>
      <SocketCustom>
      <LoadingContextProvider>
        <div className='bg-slate-1001'>
          <RouterProvider router={router}/>
        </div>
        <Loading/>
      </LoadingContextProvider>
      </SocketCustom>
      </PersistGate>
    </SnackbarProvider>
    </Provider>
  );
}

export default App;
