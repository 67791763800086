import React, { useEffect } from 'react';
import FriendMayKnow from './FriendMayKnow';
import FriendRequest from './FriendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingContext } from '../../context/loadingContext';
import * as ActionTypes from '../../controllers/ActionType'


const Friend = () => {
    const {isLoading , setIsLoading} = React.useContext(LoadingContext);
    const user = useSelector(state => state.mainReducer.user);

    const dispatch = useDispatch();
    const getUser = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_USER, setIsLoading, payload: {}})
      }
      useEffect(() => {
        getUser();

    }, [])
    return (
        <div>
            <FriendRequest />
            <FriendMayKnow user={user}/>
        </div>
    );
};

export default Friend;