import React from 'react';
import Button from '@mui/material/Button';
import { BASE_URL } from '../../utils/constants';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../controllers/ActionType'
const FriendItem = ({name, avt,request, id, fetchDatas}) => {
    const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'
    const user = useSelector(state => state.mainReducer.user); 
    const dispatch = useDispatch();
    const handleAddFriend = () => {
        dispatch({type:ActionTypes.API_ADD_FRIEND, payload:{friendSource: user.id, friendTarget: id}})
        if(request) {
            setTimeout(() => {
                dispatch({type:ActionTypes.API_GET_LIST_FRIEND_REQUEST,payload:{}})
            }, 2000);
        };
        if (fetchDatas){
            setTimeout(() => {
                fetchDatas();
            }, 2000);
        }
        // dispatch({type:ActionTypes.API_GET_LIST_FRIEND_REQUEST,payload:{}});

    }
    const handleDelFriend = () => {
        dispatch({type:ActionTypes.API_DELETE_FRIEND, payload:{friendSource: user.id, friendTarget: id}})
    }
    return (
        <div className='w-[18%] h-[310px] m-[11px] bg-white p-2 rounded-lg shadow-md cursor-pointer '>
            <img className=' h-[180px] w-full text-sm rounded-lg' src={avt ?BASE_URL + `/api/files/upload`+ avt:userAvt} alt='không tìm thấy ảnh'/>
            <Link to={`/personal/${id}`}>
                <h3 className='font-medium text-base mt-[6px] hover:text-blue-500'>{name}</h3>
            </Link>
            <Button onClick={handleAddFriend} variant="contained" className='w-full' style={{padding:'4px 0', fontSize:'12px !important', margin:'6px 0'}}>{request ?'Xác nhận':'Thêm bạn bè'}</Button>
            <Button onClick={handleDelFriend} variant="outlined" className='w-full'>{request ?'Xóa':'Xóa, gỡ bỏ'}</Button>
        </div>
    );
};

export default FriendItem;