import React from 'react';

const MessageSend = ({content, time}) => {
    return (
        <div className='mt-1 pl-[28px]'>
            <div className='items-center flex justify-end mr-[32px]'>
                <span className='w-auto max-w-[60%] ml-3 bg-[#6e00ff] text-white px-3 py-1 rounded-3xl text-sm'>
                {content}
                </span>
            </div>
            <span className='font-light text-[12px] ml-[45px] text-[#303030] flex justify-end mr-[40px]'>{time}</span>
        </div>
    );
};

export default MessageSend;