import React, { useEffect } from 'react';
import FriendItem from './FriendItem';
import { BASE_URL } from '../../utils/constants';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingContext } from '../../context/loadingContext';

const FriendMayKnow = ({user}) => {
    const [friends, setFriends] = React.useState([]);
    const token = useSelector(state => state.socialAuthReducer.token);
    const fetchDatas = async () => {
        if (!user) return;
        const res = await axios.get(`${BASE_URL}/api/v1/users/not-friend/me`,{headers: {Authorization: `Bearer ${token}`}});
        console.log(res.data.content);
        setFriends(res.data.content);
    }
    useEffect(() => {
        fetchDatas();
    }, [])
    console.log(user);
    return (
    <div className='mt-5'>
        <div className='flex items-center justify-between'>
        <h2 className='text-xl font-semibold'>Những người bạn có thể biết</h2>
        <span className='text-blue-500 cursor-pointer hover:underline '>Xem tất cả</span>
        </div>
        <div className='flex mt-3  flex-wrap w-full bg-white shadown-message max-h-[660px] have-y-scroll overflow-y-auto'>
        {friends?.map((friend) => (
            <FriendItem fetchDatas={fetchDatas} key={friend?.id} id={friend?.id} name={friend?.fullName} avt={friend?.avatar}/>
        ))}
        </div>
    </div>
    );
};

export default FriendMayKnow;