import * as React from 'react';

import { Tabs } from 'antd';
import PostsPersonal from './PostsPersonal';


const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: `Bài Viết`,
      children: <PostsPersonal/>,
    },
    {
      key: '2',
      label: `Giới thiệu`,
      children: `Content of Tab Pane 2`,
    },
    {
      key: '3',
      label: `Bạn bè`,
      children: `Content of Tab Pane 3`,
    },
    {
      key: '4',
      label: `Ảnh`,
      children: `Content of Tab Pane 4`,
    },
    {
      key: '5',
      label: `Video`,
      children: `Content of Tab Pane 5`,
    },
  ];

const BodyPersonal = () => {
    return (
        <div className='p-4 w-[72%] mx-auto tab-personal'>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
    );
};

export default BodyPersonal;