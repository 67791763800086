import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { BASE_URL } from '../../utils/constants';

function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  export default function ImageListMain({image}) {
    return (
      <ImageList
        variant="quilted"
        cols={`${image.length === 1 ? 1 : 2}`}
      
      >
        {image?.length<=4 &&image?.map((item) => (
          <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} >
            <img
              {...srcset(BASE_URL + `/api/files/upload`+ item.url, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
              style={{maxHeight:image.length===1?'500px':'250px', objectFit:'cover', objectPosition:'center', backgroundSize:'cover'}}
            />
          </ImageListItem>
        ))}
        {image?.length>=5 &&image?.slice(0, 4).map((item, index) => (
          <ImageListItem className='relative' key={item.img} cols={item.cols || 1} rows={item.rows || 1} >
            <img
              {...srcset(BASE_URL + `/api/files/upload`+ item.url, 121, item.rows, item.cols)}
              alt={item.title}
              loading="lazy"
              style={{maxHeight:image.length===1?'500px':'250px', cursor:'pointer', position:'relative', objectFit:'cover', objectPosition:'center', backgroundSize:'cover'}}
            />
            {
                image.length > 4 && index=== 3 && <div className='absolute bottom-0 cursor-pointer left-0 top-0 right-0 bg-gray-500 text-white text-center  w-full h-full flex justify-center items-center opacity-80'>{image.length - 4}+</div>
            }
           
          </ImageListItem>
        ))}
      </ImageList>
    );
  }  