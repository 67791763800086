import { call, put, select, take, takeEvery, takeLatest, takeLeading, all, delay } from 'redux-saga/effects';
import * as ActionType from '../ActionType';
import axios from 'axios';
import {BASE_URL} from '../../utils/constants'
import { sendRequestToBEMain} from '../generator'
import { enqueueSnackbar } from 'notistack';
export function* watcherMain() {
    yield takeLeading(ActionType.API_POST, apiPost);
    yield takeLeading(ActionType.API_GET_POST, apiGetPost);
    yield takeEvery(ActionType.UPDATE_GET_POST, updateGetPost)
    yield takeLeading(ActionType.API_GET_USER, apiGetUser);
    yield takeLeading(ActionType.API_GET_USER_ID, apiGetUserId);
    yield takeLeading(ActionType.API_GET_COMMENT, apiGetComment);
    yield takeEvery(ActionType.UPDATE_COMMENT, UpdateComment);
    yield takeLeading(ActionType.API_GET_COMMENT_CHILD, apiGetCommentChild);
    yield takeEvery(ActionType.UPDATE_COMMENT_CHILD, UpdateCommentChild);
    yield takeLeading(ActionType.API_UPLOAD_AVATAR, apiUploadAvatar);
    yield takeEvery(ActionType.API_SEARCH, apiSearch);
    yield takeEvery(ActionType.UPDATE_GET_USER, updateGetUser);
    yield takeEvery(ActionType.UPDATE_GET_USER_ID, updateGetUserId);
    yield takeEvery(ActionType.API_ADD_FRIEND, apiAddFriend);
    yield takeEvery(ActionType.API_DELETE_FRIEND, apiDeleteFriend);
    yield takeLeading(ActionType.API_GET_MESSENGER_ME, apiGetMessengerme);
    yield takeEvery(ActionType.UPDATE_GET_MESSENGER_ME, apiUpdateGetMessengerme);
    yield takeLeading(ActionType.API_GET_MESSAGE, apiGetMessage);
    yield takeEvery(ActionType.UPDATE_GET_MESSAGE, apiUpdateGetMessagee);
    yield takeEvery(ActionType.UPDATE_GET_LIST_FRIEND_REQUEST, updategetListFriendRequest);
    yield takeLeading(ActionType.API_GET_LIST_FRIEND_REQUEST, apiGetListFriendRequest);
}
function *updategetListFriendRequest(action) {
    try {
        const data = action.payload
        switch (action.ttype) {
            case 'fetch':{
               yield put({type: ActionType.UPDATE_GET_LIST_FRIEND_REQUEST_SUCCESS, payload: data})
            }
            break;
          
            default:  
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiUpdateGetMessagee(action) {
    try {
        const data = action.payload
        const dataMessage = yield select(state => state.mainReducer.message);
        const dataLeft = yield select(state => state.mainReducer.userConversation);
        switch (action.ttype) {
            case 'fetch':{
                yield put({type: ActionType.UPDATE_GET_MESSAGE_SUCCESS, payload: data})
            }
            break;
            case 'update':{
                // if(data[0].conversationId !== dataMessage[0].conversationId) return;
                // const newData = [...dataMessage, data[1]]
                
                const updateLastMessage = dataLeft.map((item, index) => {
                    if(item.id === data[0]) {
                        return {...item, lastMessage: data[1]}
                    }
                    return item
                })
                const newData = [...dataMessage, data[1]]
                    
                yield put({type: ActionType.UPDATE_GET_MESSENGER_ME_SUCCESS, payload: updateLastMessage})

                yield put({type: ActionType.UPDATE_GET_MESSAGE_SUCCESS, payload: data})
            }
            break;
            default:
                break;
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}

function *apiGetMessage(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        if(!action.payload) return;
        let response = yield call(sendRequestToBEMain, {
            func: '/conversations/messages/' + action.payload,
            payload: '',
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const newData = response.content
            action.setMessage(newData)
            action.setIsLoading(false)
            // yield put({ttype:'fetch',type: ActionType.UPDATE_GET_MESSAGE, payload: newData})
            // yield put({type: ActionType.UPDATE_GET_MESSENGER_ME, payload: newData})
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiDeleteFriend(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let response = yield call(sendRequestToBEMain, {
        func: '/friends',
            payload: action.payload,
            method: 'DELETE',
            donotEncrypt: true,
            token: token
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data = response
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiAddFriend(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let response = yield call(sendRequestToBEMain, {
        func: '/friends',
            payload: action.payload,
            method: 'POST',
            donotEncrypt: true,
            token: token
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data = response
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiSearch(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let response = yield call(sendRequestToBEMain, {
            func: '/users/search?keyword='+action.payload,
            payload: '',
            method: 'GET',
            donotEncrypt: true,
            token: token
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data = response.content
            action.setResult(data)
            
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiUploadAvatar(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        const user = yield select(state => state.mainReducer.user);
        let newData = new FormData();
        newData.append('file', action.payload[0]);
        let response = yield call(sendRequestToBEMain, {
            func: '/users/' + user.id,
            payload: newData,
            method: 'PUT',
            donotEncrypt: true,
            token: token
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data = response
            yield put({ttype:'update', type: ActionType.UPDATE_GET_USER, payload: data})
            enqueueSnackbar('Cập nhật ảnh đại diện thành công', { variant: 'success' });
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiPost(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        const post = yield select(state => state.mainReducer.post);
        let data= {...action.payload, files: action.files}
        let newData = new FormData();
        newData.append('content', data.content);
        newData.append('scopeType', data.scopeType);
        for (let i = 0; i < data.files.length; i++) {
            newData.append('files', data.files[i]);
        }
        let response = yield call(sendRequestToBEMain, {
            func: '/posts',
            payload: newData,
            donotEncrypt: true,
            token: token
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data = response
            const newData = [data, ...post]
            yield put({type: ActionType.UPDATE_GET_POST, ttype:'fetch', payload: data})
            action.setIsLoading(false)
            enqueueSnackbar('Tạo bài viết thành công', { variant: 'success' });
            action.setOpen(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *updateGetUser(action) {
    try {
        const data = action.payload
        switch (action.ttype) {
            case 'fetch':{
                yield put({type: ActionType.UPDATE_GET_USER_SUCCESS, payload: data})
            }
            break;
            case 'update':{
                const newData = data
                yield put({type: ActionType.UPDATE_GET_USER_SUCCESS, payload: newData})
            }
            break;
            default:
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiUpdateGetMessengerme(action) {
    try {
        const data = action.payload
        switch (action.ttype) {
            case 'fetch':{
                yield put({type: ActionType.UPDATE_GET_MESSENGER_ME_SUCCESS, payload: data})
            }
            break;
            case 'fetchUser':{
                if(action.id){
                    const dataUser = data.filter(item => item.id === action.id)
                    yield put({type: ActionType.UPDATE_GET_MESSENGER_USER_SUCCESS, payload: dataUser[0]})
                } else {
                    const dataUser = data[0]
                    yield put({type: ActionType.UPDATE_GET_MESSENGER_USER_SUCCESS, payload: dataUser})
                }
            }
            break;
            default:
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *updateGetUserId(action) {
    try {
        const data = action.payload
        switch (action.ttype) {
            case 'fetch':{
                const statusFriend= data?.statusFriend
                yield put({type: ActionType.UPDATE_GET_USER_ID_SUCCESS, payload: data.user})
                yield put({type: ActionType.UPDATE_STATUS_FRIEND_SUCCESS, payload: statusFriend})
            }
            break;
            case 'update':{
                yield put({type: ActionType.UPDATE_STATUS_FRIEND_SUCCESS, payload: data})
            }
            break;
            default:  
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *updateGetPost(action) {
    try {
        const post = yield select(state => state.mainReducer.post);
        const user = yield select(state => state.mainReducer.user);
        const data = action.payload
    
        switch (action.ttype) {
            case 'update':{
                const newData = [{...data,reaction:[]},...post]
                yield put({type: ActionType.UPDATE_GET_POST_SUCCESS, payload: newData})
            }
            break;
            case 'fetch':{
                yield put({type: ActionType.UPDATE_GET_POST_SUCCESS, payload: data})
            }
            break;
            case 'updateReaction':{
                const index = post.findIndex(item => item.id === data.postId)
               
                if(user.id === data.user.id) {
                    post[index].userReaction = data.reactionType
                }
                if(!data.reactionTypeCurrent) {
                    if(post[index].reaction.length === 0) {
                        post[index].reaction.push([data.reactionType, 1])
                    } else {
                        const indexReactionNew = post[index].reaction.findIndex(item => item[0] === data.reactionType)
                        if(indexReactionNew === -1) {
                            post[index].reaction.push([data.reactionType, 1])
                        } else {
                            post[index].reaction[indexReactionNew][1] += 1
                        }
                    }

                } else {
                    const indexReaction = post[index].reaction.findIndex(item => item[0] === data.reactionTypeCurrent)
                    if(indexReaction === -1) {
                        post[index].reaction.push([data.reactionType, 1])
                    } else {
                        post[index].reaction[indexReaction][1] -= 1
                        const indexReactionNew = post[index].reaction.findIndex(item => item[0] === data.reactionType)
                        if(indexReactionNew === -1) {
                            post[index].reaction.push([data.reactionType, 1])
                        } else {
                            post[index].reaction[indexReactionNew][1] += 1
                        }

                    }

                }
                yield put({type: ActionType.UPDATE_GET_POST_SUCCESS, payload: post})
            }
            break;
            case 'updateReactionDel':{
                const index = post.findIndex(item => item.id === data.postId)
                if(user.id === data.user.id) {
                    post[index].userReaction = null
                }
                const indexReaction = post[index].reaction.findIndex(item => item[0] === data.reactionType)
                post[index].reaction[indexReaction][1] -= 1
                yield put({type: ActionType.UPDATE_GET_POST_SUCCESS, payload: post})
            }
            break;
            default:
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiGetMessengerme(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let response = yield call(sendRequestToBEMain, {
            func: '/conversations/me',
            payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data = response.content
            yield put({ttype:'fetch',type: ActionType.UPDATE_GET_MESSENGER_ME, payload: data})
            yield put({ttype:'fetchUser',type: ActionType.UPDATE_GET_MESSENGER_ME, payload: data, id:action.id})
            action.setIsLoading(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiGetPost(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let response = yield call(sendRequestToBEMain, {
            func: '/posts/timeline/me',
            payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const newData = response.content
            yield put({ttype:'fetch',type: ActionType.UPDATE_GET_POST, payload: newData})
            // yield put({type: ActionType.UPDATE_GET_POST_SUCCESS, payload: newData})
            action.setIsLoading(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiGetListFriendRequest(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let response = yield call(sendRequestToBEMain, {
            func: '/users/request-friend/me',
            payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const newData = response.content
            yield put({ttype:'fetch',type: ActionType.UPDATE_GET_LIST_FRIEND_REQUEST, payload: newData})
            action.setIsLoading(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiGetUser(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let response = yield call(sendRequestToBEMain, {
            func: '/users/profile/me',
            payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const newData = response
            yield put({ttype:'fetch',type: ActionType.UPDATE_GET_USER, payload: newData})
            action.setIsLoading(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
function *apiGetUserId(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let response = yield call(sendRequestToBEMain, {
            func: '/users/profile/'+action.payload,
            payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const newDataUserId = response
            const newDataPost = response.posts.content
            yield put({ttype:'fetch',type: ActionType.UPDATE_GET_USER_ID, payload: newDataUserId})
            yield put({ttype:'fetch',type: ActionType.UPDATE_GET_POST, payload: newDataPost})
            action.setIsLoading(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}

function *apiGetComment(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let parent_id = action?.payload?.parent_id
        let response = yield call(sendRequestToBEMain, {
            func: `/comments/post/`+action.payload,
            // func: `/comments/post/`+action.payload +`${parent_id ? `?parent_id=${parent_id}` : ''}}`,
            payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data  = response.content
            yield put({ttype:'fetch',type: ActionType.UPDATE_COMMENT, payload: data})
            action.setIsLoading(false)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}

function *UpdateComment(action) {
    try {
        const data = action.payload
        const comments = yield select(state => state.mainReducer.comments);
        const commentsChild = yield select(state => state.mainReducer.commentsChild);
        const post = yield select(state => state.mainReducer.post);
        switch (action.ttype) {
            case 'fetch':{
                yield put({type: ActionType.UPDATE_COMMENT_SUCCESS, payload: data})
            }
            break;
            case 'update':{
                const newData = [data, ...comments]
                const isChild= comments.filter(item => item.id === data.parentId)
                const postId= post.filter(item => item.id === data.postId)
               
                const updateCmt =post.map(item => {
                    if(item.id === data.postId) {
                        item.totalComment += 1
                    }
                    return item
                })
                if(isChild.length === 1) {
                    const index = comments.findIndex(item => item.id === data.parentId)
                    comments[index].totalCommentChild += 1
                    const newData = [data, ...commentsChild]
                    yield put({type: ActionType.UPDATE_COMMENT_CHILD_SUCCESS, payload: newData})
                    yield put({type: ActionType.UPDATE_COMMENT_SUCCESS, payload: comments})
                } else {
                    yield put({ttype:'fetch',type: ActionType.UPDATE_GET_POST, payload: updateCmt})
                    yield put({type: ActionType.UPDATE_COMMENT_SUCCESS, payload: newData})

                }
            }
            break;
            default:
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}

function *apiGetCommentChild(action) {
    try {
        const token = yield select(state => state.socialAuthReducer.token);
        let data= {...action.payload}
        let parent_id = data?.id_ParentComment
        let id_Post = data?.id_Post
        let response = yield call(sendRequestToBEMain, {
            func: `/comments/post/`+id_Post +`${parent_id ? `?parent-id=${parent_id}` : ''}`,
            // payload: data,
            donotEncrypt: true,
            token: token,
            method:"GET"
        })
        if(response?.code) {
            if(!response.errors) {
                enqueueSnackbar(response.errors.message, { variant: 'error' });
            } else {
                for(var error in response.errors) {
                    enqueueSnackbar(error+" "+response.errors[error], { variant: 'error' });
                }
            }
        } else {
            const data  = response.content
            yield put({ttype:'fetch',type: ActionType.UPDATE_COMMENT_CHILD, payload: data,listCommentChild: action.setReply})
            yield delay(200)
            // action.setShow(true)
            action.setShowReply(true)
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}

function *UpdateCommentChild(action) {
    try {
        const data = action.payload
        const comments = yield select(state => state.mainReducer.comments);
    
        switch (action.ttype) {
            case 'fetch':{
                // yield put({type: ActionType.UPDATE_COMMENT_CHILD_SUCCESS, payload: data})
                action.listCommentChild(data)
            }
            break;
            case 'update':{
            }
            break;
            default:
                break;
                
        }

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}
