import React from 'react';
import FriendHome from './FriendHome';
import Post from './Post';
import PostContainer from './PostContainer';
import { useSelector } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import { Box } from '@mui/material';

const BodyHome = () => {
    const dataPost = useSelector(state => state.mainReducer.post);
    return (
        <div className='flex mt-5 space-x-3'>
            <div className='mr-[404px]'>
                <Post/>
                {dataPost?.map((item, index) => {
                    const reaction =item?.reaction ? item.reaction : [];
                    const numberLike = reaction.reduce((total, itemReaction) => {
                        return total + itemReaction[1]
                    }, 0)
                    return (
                            <PostContainer
                                key={index}
                                idPost={item?.id} 
                                username={item?.userCreate?.fullname} 
                                time={item?.createdAt} content={item?.content}
                                avt ='https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8YXZhdGFyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60'
                                image={item?.fileEntities}
                                scopeType={item?.scopeType}
                                numberlike={numberLike}
                                numbercomment={item?.totalComment ? item.totalComment: 0}
                                numbershare='100'
                                {...item}
                            />
                    )
                })}
                <Box sx={{display:'flex', justifyContent:'center', marginTop:'4px'}}>
                    <Pagination count={10} variant="outlined" color="primary" />

                </Box>
            </div>
            <FriendHome/>           
        </div>
    );
};

export default BodyHome;