import React from "react";
import { useController } from "react-hook-form";

const TextareaHook = ({ control, ...props }) => {
  const { field } = useController({
    control,
    name: props.name,
    defaultValue: "",
  });
  return (
    <textarea
    autoComplete="false"
      type="text"
      className="p-3 transition-all   bg-transparent text-white border-b-2 border-white placeholder:text-[#bab6b9] outline-none focus:"
      {...field}
      {...props}
    />
  );
};

export default TextareaHook;
