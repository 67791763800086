import { Avatar } from 'antd';
import React from 'react';

const MessageReceiver = ({avt, content, time}) => {
    return (
    <div className=''>
        <div className='flex items-center'>
            <Avatar alt="Remy Sharp" src={avt} />
            <span className='w-auto max-w-[60%] ml-3 bg-[#e7e7e7] text-[#303030] px-3 py-1 rounded-3xl text-sm'>
               {content}
            </span>
        </div>
        <span className='font-light text-[12px] ml-[45px] text-[#303030]'>{time}</span>
    </div>
    );
};

export default MessageReceiver;