import React from 'react';

const Watch = () => {
    return (
        <div>
            Day la trang xem video
        </div>
    );
};

export default Watch;