import { call, put, select, take, takeEvery, takeLatest, takeLeading, all, delay } from 'redux-saga/effects';
import * as ActionType from '../ActionType';
import axios from 'axios';
import {BASE_URL} from '../../utils/constants'
import {sendRequestToBE} from '../generator'
export function* watcherAuth() {
    yield takeLeading(ActionType.UPDATE_LOGOUT_TOKEN, logoutSaga);
    yield takeLeading(ActionType.API_REGISTER, apiRegister);
    yield takeLeading(ActionType.API_LOGIN_TOKEN, apiLoginToken);
  
}
function *apiLoginToken(action) {
    try {
        let data= action.payload
        let dataSend= {email: data.email, password: data.password}
        let response = yield call(sendRequestToBE, {
            func: '/auth/authenticate',
            payload: dataSend,
            donotEncrypt: true,
            method: 'POST'
        })
        yield delay(2000)
        if(response.code) {
            action.notify({message:'Tên tài khoản hoặc mật khẩu không chính xác', status:'error'})
        } else {
            yield put({type: ActionType.UPDATE_LOGIN_TOKEN, payload: response})
            action.setIsLoading(false)
            action.navigate('/')
        }
      

    }catch(error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }
}


function * apiRegister(action) {
    try {
        let data= action.payload
        const toDay = new Date().toISOString().split('T')[0]
        let dataSend= {email: data.email,fullName: data.fullName, password: data.password, dob:data.date || toDay, genderType:data.genderType}
        let response = yield call(sendRequestToBE, {
            func: '/auth/register',
            payload: dataSend,
            donotEncrypt: true,
            method: 'POST'
        })
        yield delay(2000)
        if(response.code) {
            action.notify({message:response.message, status:'error'})
            action.reset({
                fullName: "",
                email: "",
                password: "",
              })
        } else {
            action.notify({message:'Đăng ký thành công', status:'success'})
            action.setIsLoading(false)
            action.navigate('/signin')
        }
      
    } catch (error) {
        if(error.code) {
            return { code: "NO-CONNECTION", message: "Không thể kết nối đến Hệ thống" }
        } else {
            return { code: "UNKNOWN-ERROR", message: "Lỗi không xác định khi kết nối Hệ thống" }
        }
    }

}
function* logoutSaga(action) {
    try {
        yield put({type: ActionType.UPDATE_LOGIN_TOKEN, payload: action.payload})
        action.navigate('/signin')
    } catch (error) {
        
    }

}