import React, { useState } from 'react';
import { Avatar, Select, Tooltip } from '@mui/material';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import ImageIcon from '@mui/icons-material/Image';
import TagFacesIcon from '@mui/icons-material/TagFaces';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, } from "react-hook-form";
import PublicIcon from '@mui/icons-material/Public';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TextareaHook from '../input/TextareaHook';

import { Text, Image, SimpleGrid } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from '@mantine/dropzone';
import './BodyHome.scss'
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../controllers/ActionType';
import { LoadingContext } from '../../context/loadingContext';
import { BASE_URL } from '../../utils/constants';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const Post = () => {
  const [files, setFiles] = useState([]);
  const user = useSelector(state => state.mainReducer.user)
  const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'
  function ImagePost() {
    const previews = files.map((file, index) => {
      const imageUrl = URL.createObjectURL(file);
      const handleDelImage = () => {
        setFiles(files => files.filter((_, i) => i !== index));
      }
      return (
        <div className='relative'>
        <Image
          key={index}
          src={imageUrl}
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />  
        <div className='close-image-review' onClick={handleDelImage}>
          <CloseIcon className='close-image-review-icon'/>
        </div>

        </div>
      );
    });
  
    return (
      <div>
        <Dropzone accept={IMAGE_MIME_TYPE} onDrop={(filesDrop)=>{
          setFiles(files=>[...files, ...filesDrop])
        }}>
          <Text align="center">Drop images here</Text>
        </Dropzone>
  
        <SimpleGrid
          cols={4}
          breakpoints={[{ maxWidth: 'sm', cols: 1 }]}
          mt={previews.length > 0 ? 'xl' : 0}
        >
          {previews}
        </SimpleGrid>
      </div>
    );
  }

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [addImage, setAddImage] = useState(false);
  const handleClose = () => {setOpen(false); setAddImage(false)};
  const {isLoading, setIsLoading} = React.useContext(LoadingContext);

  const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm();
  const onSubmit = data => {
    setIsLoading(true)
    dispatch({type: ActionTypes.API_POST, payload: data, files: files,setIsLoading, setOpen})
    reset( {
        content:''
    })
    setFiles([])
    setAddImage(false)
  } 
    const name = user?.fullName?.split(' ')
    const firstName = name?.[name?.length - 1]
    return ( 
            <div className='flex flex-col p-3 bg-white shadown-full-1 rounded-xl'>
                <div className='flex items-center'>
                    <Avatar alt="Remy Sharp" src={user.avatar ?BASE_URL + `/api/files/upload`+ user.avatar:userAvt} />
                    <Button style={{backgroundColor:'#e2e8f0', marginLeft:'16px', borderRadius:'16px'}} className='w-full ml-4 bg-slate-200 ' onClick={handleOpen}>{firstName}, bạn đang nghĩ gì thế ?</Button>   
                </div>
                <div className='w-full h-[2px] bg-slate-200 mt-4'></div>
                <div className='flex items-center justify-around mt-4'>
                    <div className='px-2 py-1 transition-all rounded-md hover:bg-slate-200'>
                    <VideoCameraBackIcon className='text-pink-600'/>
                    <span className='ml-4 text-sm font-semibold cursor-pointer text-gray-main' >Video trực tiếp</span>
                    </div>
                    <div className='px-2 py-1 transition-all rounded-md hover:bg-slate-200'>
                    <ImageIcon className='text-green-600'/>
                    <span className='ml-4 text-sm font-semibold cursor-pointer text-gray-main' >Ảnh/video</span>
                    </div>
                    <div className='px-2 py-1 transition-all rounded-md hover:bg-slate-200'>
                    <TagFacesIcon className='text-yellow-600'/>
                    <span className='ml-4 text-sm font-semibold cursor-pointer text-gray-main' >Cảm xúc/ hoạt động</span>
                    </div>
                    
                </div>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    className='modal-post'
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                    }}
                >
                    <Fade in={open}>
                    <Box sx={style} style={{padding:'12px', border:'none', borderRadius:'8px' }} className='modal-post-content'>
                    <div className='flex justify-between'>
                        <div></div>
                        <Typography className='text-base font-semibold text-center' id="transition-modal-title" variant="h6" style={{fontWeight:'600', fontSize:'18px'}} component="h2">
                        Tạo bài viết
                        </Typography>
                        <CloseIcon onClick={handleClose} className='cursor-pointer'/>
                    </div>
                        <div className='h-[1px] bg-[#ccc]'></div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='mt-3'>
                            <div className='flex flex-col '>
                                <div className='flex items-center pl-1 shadow-black'>
                                <Avatar alt="Remy Sharp" src={user.avatar ?BASE_URL + `/api/files/upload`+ user.avatar:userAvt} />
                               <div className='flex flex-col ml-3'>
                                <span className='ml-2 text-[#050505]'>{user?.fullName}</span>
                                 <select {...register("scopeType", { required: true })} className='px-1 text-xs rounded-sm outline-none cursor-pointer bg-slate-200'>
                                    <option value="PUBLIC"><PublicIcon/>Công khai</option>
                                    <option value="PRIVATE">Chỉ mình tôi</option>
                                    <option value="FRIEND">Bạn bè</option>
                                 </select>
                               </div>
                                </div>
                            </div>
                                <div className="flex flex-col mt-3 mb-2">
                                <TextareaHook
                                    name="content"
                                    className='px-3 py-2 text-black border-none rounded-lg outline-none'
                                    placeholder={`${firstName} ơi, bạn đang nghĩ gì ?`}
                                    id="content"
                                    control={control}
                                    type="text"
                                ></TextareaHook>
                                </div>
                                {addImage && 
                                    <div>
                                        <ImagePost/>
                                    </div>

                                }
                            <div className='mt-3 mb-3 border-[1px] border-gray-main p-3 rounded-lg'>
                                <span className='text-sm'>Thêm vào bài viết của bạn</span>
                                <Tooltip title={"Ảnh/video"}>
                                 <ImageIcon onClick={()=> setAddImage(!addImage) } className='ml-4 text-green-600 cursor-pointer'/>
                                </Tooltip>
                                <Tooltip title={"Gắn thẻ người khác"}>
                                 <PersonAddIcon className='ml-2 text-blue-600 cursor-pointer'/>
                                </Tooltip>
                                <Tooltip title={"Cảm xúc/hoạt động"}>
                                 <TagFacesIcon className='ml-2 text-yellow-600 cursor-pointer'/>
                                </Tooltip>
                                <Tooltip title={"Check in"}>
                                 <WhereToVoteIcon className='ml-2 text-red-600 cursor-pointer'/>
                                </Tooltip>
                                <Tooltip title={"Tùy chọn"}>
                                 <MoreHorizIcon className='cursor-pointer text-gray-main'/>
                                </Tooltip>
                            </div>
                            <Button className='block mt-3' type='submit' variant="contained">Đăng</Button>
                            </div> 
                        </form>
                    </Box>
                    </Fade>
                </Modal>
            </div>
    );
};

export default Post;