import React, { useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import Header from '../../Header/Header';
import MainLeft from '../../Header/MainLeft';
import { useSelector } from 'react-redux';


const Main = () => {
    const token = useSelector(state => state.socialAuthReducer.token)
    const navigate = useNavigate();
    useEffect(() => {
        if (!token) {
          navigate('/signin');
          return
        }
      }, [])
    return (
        <React.Fragment>
        <Header/>
        <div className='bg-slate-100 h-auto mt-[62px] flex'>
                <MainLeft/>
                <div className='w-[80%] bg-slate-100 p-3 rounded-lg  mt-4 ml-[20%] App11'>
                <Outlet/>
                </div>
        </div>
    </React.Fragment>
    );
};

export default Main;