import React, { useEffect } from 'react';
import HeaderPersonal from '../components/personal/HeaderPersonal';
import Header from '../Header/Header';
import '../components/personal/Personal.scss'
import BodyPersonal from '../components/personal/BodyPersonal';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from "../controllers/ActionType";
import { LoadingContext } from "../context/loadingContext";
import { useParams } from 'react-router-dom';


const Personal = () => {
    const dispatch = useDispatch()
    const {isLoading , setIsLoading} = React.useContext(LoadingContext);
    const useParam = useParams();
    const user = useSelector(state => state.mainReducer.user)
    const userId = useSelector(state => state.mainReducer.userId)
    const statusFriend = useSelector(state => state.mainReducer.statusFriend)
    const getUser = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_USER, setIsLoading, payload: {}})
      }
    const getUserId = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_USER_ID, setIsLoading, payload:  useParam.id})
    }
      const getPost = ()=> {
        setIsLoading(true)
        dispatch({type: ActionTypes.API_GET_POST,setIsLoading, payload: {}})
      }
    useEffect(() => {
        if(useParam.id){
            getUserId();
        } else {
            getUser();
            getPost();
        }
    }, [useParam])
    return (
        <div>
        <Header/>
        <div className='mt-[60px] text-black bg-slate-100'>
           <HeaderPersonal statusFriend={statusFriend} isFriend={useParam.id} user={useParam.id ? userId :user}/>
           <BodyPersonal/>
        </div>
        </div>
    );
};

export default Personal;