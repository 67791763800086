export const UPDATE_LOGOUT_TOKEN = '100';
export const AUTH_INITIATE_LOGOUT_SUCCESS = '1001';

export const API_REGISTER ='200'
export const UPDATE_REGISTER ='201'
export const UPDATE_REGISTER_SUCCESS ='2011'
export const API_LOGIN_TOKEN ='300'
export const UPDATE_LOGIN_TOKEN ='301'
export const UODATE_LOGIN_TOKEN_SUCCESS ='3011'
export const API_POST ='400'
export const API_GET_POST ='500'
export const UPDATE_GET_POST ='501'
export const UPDATE_GET_POST_SUCCESS ='5011'

export const API_GET_USER ='600'
export const UPDATE_GET_USER ='601'
export const UPDATE_GET_USER_SUCCESS ='6011'

export const API_SEARCH ='700'
export const UPDATE_SEARCH ='701'

export const API_GET_COMMENT ='800'
export const UPDATE_COMMENT ='801'
export const UPDATE_COMMENT_SUCCESS ='8011'

export const API_GET_COMMENT_CHILD ='900'
export const UPDATE_COMMENT_CHILD ='901'
export const UPDATE_COMMENT_CHILD_SUCCESS ='9011'

export const API_UPLOAD_AVATAR ='1000'
export const API_GET_USER_ID ='1100'
export const UPDATE_GET_USER_ID ='1101'
export const UPDATE_GET_USER_ID_SUCCESS ='11011'
export const UPDATE_STATUS_FRIEND_SUCCESS ='1102'
export const API_ADD_FRIEND ='1200'
export const API_DELETE_FRIEND ='1300'
export const API_GET_MESSENGER_ME ='1400'
export const UPDATE_GET_MESSENGER_ME ='1401'
export const UPDATE_GET_MESSENGER_ME_SUCCESS ='14011'
export const UPDATE_GET_MESSENGER_USER_SUCCESS ='14012'
export const API_GET_MESSAGE ='1500'
export const UPDATE_GET_MESSAGE ='1501'
export const UPDATE_GET_MESSAGE_SUCCESS ='15011'
export const API_GET_LIST_FRIEND_REQUEST ='1600'
export const UPDATE_GET_LIST_FRIEND_REQUEST ='1601'
export const UPDATE_GET_LIST_FRIEND_REQUEST_SUCCESS ='16011'


