import React, { useContext } from 'react';
import { Avatar, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublicIcon from '@mui/icons-material/Public';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import CommentIcon from '@mui/icons-material/Comment';
import ReplyIcon from '@mui/icons-material/Reply';
import { Input } from 'antd';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import AttachmentIcon from '@mui/icons-material/Attachment';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import { BASE_URL } from '../../utils/constants';
import ImageListMain from './ImageListItem';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HttpsIcon from '@mui/icons-material/Https';
import Comments from '../comments/Comments';
import {Popover } from 'antd';
import { SocketContext } from '../../config/SocketCustom';
import { useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
  };
  

const IconLike = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      id="like"
      {...props}
    >
      <path fill="#0073c2" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Z" />
      <path
        fill="#fff"
        d="M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725.1.163.132.36.089.546-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666ZM3.6 7h.8a.6.6 0 0 1 .6.6v3.8a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6V7.6a.6.6 0 0 1 .6-.6Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={8}
          x2={8}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18AFFF" />
          <stop offset={1} stopColor="#0062DF" />
        </linearGradient>
      </defs>
    </svg>
  );

const IconHaha = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      id="haha"
      {...props}
    >
      <path fill="#E7B549" d="M16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8" />
      <path
        fill="#1C2033"
        d="M3 8.008C3 10.023 4.006 14 8 14c3.993 0 5-3.977 5-5.992C13 7.849 11.39 7 8 7c-3.39 0-5 .849-5 1.008Z"
      />
      <path
        fill="url(#c)"
        d="M4.541 12.5c.804.995 1.907 1.5 3.469 1.5 1.563 0 2.655-.505 3.459-1.5-.551-.588-1.599-1.5-3.459-1.5s-2.917.912-3.469 1.5Z"
      />
      <path
        fill="#2A3755"
        d="M6.213 4.144c.263.188.502.455.41.788-.071.254-.194.369-.422.37-.78.012-1.708.256-2.506.613-.065.029-.197.088-.332.085-.124-.003-.251-.058-.327-.237-.067-.157-.073-.388.276-.598.545-.33 1.257-.48 1.909-.604-.41-.303-.85-.56-1.315-.768-.427-.194-.38-.457-.323-.6.127-.317.609-.196 1.078.026a9 9 0 0 1 1.552.925Zm3.577 0a8.955 8.955 0 0 1 1.55-.925c.47-.222.95-.343 1.078-.026.057.143.104.406-.323.6a7.028 7.028 0 0 0-1.313.768c.65.123 1.363.274 1.907.604.349.21.342.44.276.598-.077.18-.203.234-.327.237-.135.003-.267-.056-.332-.085-.797-.357-1.725-.6-2.504-.612-.228-.002-.351-.117-.422-.37-.091-.333.147-.6.41-.788v-.001Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={8}
          x2={8}
          y1={1.64}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEEA70" />
          <stop offset={1} stopColor="#F69B30" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={8}
          x2={8}
          y1={7}
          y2={14}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#472315" />
          <stop offset={1} stopColor="#8B3A0E" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={8.005}
          x2={8.005}
          y1={11}
          y2={13.457}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC607C" />
          <stop offset={1} stopColor="#D91F3A" />
        </linearGradient>
      </defs>
    </svg>
  );

  const IconLove = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      id="love"
      {...props}
    >
      <path fill="#DE524C" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Z" />
      <path
        fill="#fff"
        d="M10.473 4C8.275 4 8 5.824 8 5.824S7.726 4 5.528 4c-2.114 0-2.73 2.222-2.472 3.41C3.736 10.55 8 12.75 8 12.75s4.265-2.2 4.945-5.34c.257-1.188-.36-3.41-2.472-3.41Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={8}
          x2={8}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6680" />
          <stop offset={1} stopColor="#E61739" />
        </linearGradient>
      </defs>
    </svg>
  );

const IconWow = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    id="wow"
    {...props}
  >
    <g clipPath="url(#a)">
      <path fill="#E7B549" d="M16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8" />
      <path
        fill="#000"
        d="M5.643 10.888C5.485 12.733 6.37 14 8 14c1.63 0 2.515-1.267 2.357-3.112C10.2 9.042 9.242 8 8 8c-1.242 0-2.2 1.042-2.357 2.888Z"
      />
      <path
        fill="#000"
        d="M3.5 5.5c0-.828.559-1.5 1.25-1.5S6 4.672 6 5.5C6 6.329 5.441 7 4.75 7S3.5 6.329 3.5 5.5Zm6.5 0c0-.828.56-1.5 1.25-1.5.691 0 1.25.672 1.25 1.5 0 .829-.559 1.5-1.25 1.5C10.56 7 10 6.329 10 5.5Z"
      />
      <path
        fill="#000"
        d="M3.5 5.5c0-.828.559-1.5 1.25-1.5S6 4.672 6 5.5C6 6.329 5.441 7 4.75 7S3.5 6.329 3.5 5.5Zm6.5 0c0-.828.56-1.5 1.25-1.5.691 0 1.25.672 1.25 1.5 0 .829-.559 1.5-1.25 1.5C10.56 7 10 6.329 10 5.5Z"
        filter="url(#e)"
      />
      <path
        fill="#4E506A"
        d="M4.481 4.567c.186.042.29.252.232.47-.057.217-.254.36-.44.317-.186-.042-.29-.252-.232-.47.057-.216.254-.36.44-.317Zm6.659.063c.205.047.321.28.258.52-.064.243-.282.4-.49.354-.205-.046-.322-.28-.258-.52.063-.243.282-.4.49-.354Z"
      />
      <path
        fill="#000"
        d="M11.068 1.696c.052-.005.104-.007.157-.007.487 0 .99.204 1.372.562a.368.368 0 0 1-.087.594.344.344 0 0 1-.387-.06c-.275-.26-.656-.4-.992-.37a.8.8 0 0 0-.59.332.346.346 0 0 1-.49.068.368.368 0 0 1-.068-.507 1.49 1.49 0 0 1 1.085-.612Zm-7.665.555c.371-.353.86-.553 1.372-.562a1.49 1.49 0 0 1 1.242.619.369.369 0 0 1-.066.507.347.347 0 0 1-.492-.068.8.8 0 0 0-.59-.331c-.335-.031-.717.11-.992.369a.344.344 0 0 1-.496-.024.368.368 0 0 1 .022-.51Z"
        filter="url(#f)"
      />
      <path
        fill="url(#g)"
        d="M11.068 1.696c.052-.005.104-.007.157-.007.487 0 .99.204 1.372.562a.368.368 0 0 1-.087.594.344.344 0 0 1-.387-.06c-.275-.26-.656-.4-.992-.37a.8.8 0 0 0-.59.332.346.346 0 0 1-.49.068.368.368 0 0 1-.068-.507 1.49 1.49 0 0 1 1.085-.612Zm-7.665.555c.371-.353.86-.553 1.372-.562a1.49 1.49 0 0 1 1.242.619.369.369 0 0 1-.066.507.347.347 0 0 1-.492-.068.8.8 0 0 0-.59-.331c-.335-.031-.717.11-.992.369a.344.344 0 0 1-.496-.024.368.368 0 0 1 .022-.51Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={8}
        x2={8}
        y1={1.64}
        y2={16}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEEA70" />
        <stop offset={1} stopColor="#F69B30" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={8}
        x2={8}
        y1={8}
        y2={14}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#472315" />
        <stop offset={1} stopColor="#8B3A0E" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={8}
        x2={8}
        y1={4}
        y2={7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191A33" />
        <stop offset={0.872} stopColor="#3B426A" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={8}
        x2={8}
        y1={1.688}
        y2={2.888}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E78E0D" />
        <stop offset={1} stopColor="#CB6000" />
      </linearGradient>
      <filter
        id="e"
        width={9}
        height={3}
        x={3.5}
        y={4}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.0980392 0 0 0 0 0.101961 0 0 0 0 0.2 0 0 0 0.819684 0" />
        <feBlend in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="f"
        width={15.422}
        height={7.199}
        x={0.289}
        y={-0.312}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={1.5} />
        <feColorMatrix values="0 0 0 0 0.803922 0 0 0 0 0.388235 0 0 0 0 0.00392157 0 0 0 0.145679 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
  );
const SadIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      id="sad"
      {...props}
    >
      <path fill="#E7B549" d="M16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8" />
      <path
        fill="url(#b)"
        d="M5.333 12.765c0 .137.094.235.25.235.351 0 .836-.625 2.417-.625s2.067.625 2.417.625c.156 0 .25-.098.25-.235C10.667 12.368 9.828 11 8 11c-1.828 0-2.667 1.368-2.667 1.765Z"
      />
      <path
        fill="url(#c)"
        d="M3.599 8.8c0-.81.509-1.466 1.134-1.466.627 0 1.134.656 1.134 1.466 0 .338-.09.65-.238.898a.492.492 0 0 1-.301.225c-.14.037-.353.077-.595.077-.243 0-.453-.04-.595-.077a.49.49 0 0 1-.3-.225 1.741 1.741 0 0 1-.24-.898Zm6.534 0c0-.81.508-1.466 1.133-1.466.627 0 1.134.656 1.134 1.466 0 .338-.09.65-.238.898a.49.49 0 0 1-.301.225c-.39.101-.8.101-1.19 0a.49.49 0 0 1-.3-.225 1.74 1.74 0 0 1-.238-.898Z"
      />
      <path
        fill="#000"
        d="M3.599 8.8c0-.81.509-1.466 1.134-1.466.627 0 1.134.656 1.134 1.466 0 .338-.09.65-.238.898a.492.492 0 0 1-.301.225c-.14.037-.353.077-.595.077-.243 0-.453-.04-.595-.077a.49.49 0 0 1-.3-.225 1.741 1.741 0 0 1-.24-.898Zm6.534 0c0-.81.508-1.466 1.133-1.466.627 0 1.134.656 1.134 1.466 0 .338-.09.65-.238.898a.49.49 0 0 1-.301.225c-.39.101-.8.101-1.19 0a.49.49 0 0 1-.3-.225 1.74 1.74 0 0 1-.238-.898Z"
        filter="url(#d)"
      />
      <path
        fill="#4E506A"
        d="M4.616 7.986c.128.125.136.372.017.55-.12.179-.32.223-.448.097-.128-.125-.135-.372-.017-.55.12-.18.32-.222.448-.097Zm6.489 0c.128.125.136.372.018.55-.12.179-.32.223-.45.097-.127-.125-.134-.372-.015-.55.119-.18.319-.222.447-.097Z"
      />
      <path
        fill="url(#e)"
        d="M4.157 5.153c.332-.153.596-.22.801-.22.277 0 .451.12.55.307.175.329.096.4-.198.459-1.106.224-2.217.942-2.699 1.39-.3.28-.589-.03-.436-.274.154-.244.774-1.105 1.982-1.662Zm6.335.087c.1-.187.273-.306.55-.306.206 0 .47.066.801.219 1.208.557 1.828 1.418 1.981 1.662.153.244-.134.554-.435.274-.483-.448-1.593-1.166-2.7-1.39-.294-.058-.37-.13-.197-.46Z"
      />
      <path
        fill="url(#f)"
        d="M13.5 16c-.828 0-1.5-.748-1.5-1.671 0-.922.356-1.545.643-2.147.598-1.258.716-1.432.857-1.432.141 0 .259.174.857 1.432.287.602.643 1.225.643 2.147 0 .923-.672 1.671-1.5 1.671Z"
      />
      <path
        fill="url(#g)"
        d="M13.5 13.606c-.328 0-.594-.296-.594-.66 0-.366.141-.613.255-.852.236-.498.283-.566.34-.566.055 0 .102.068.338.566.114.24.255.486.255.851s-.266.661-.594.661"
      />
      <defs>
        <linearGradient
          id="a"
          x1={8}
          x2={8}
          y1={1.64}
          y2={16}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEEA70" />
          <stop offset={1} stopColor="#F69B30" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={8}
          x2={8}
          y1={11}
          y2={13}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#472315" />
          <stop offset={1} stopColor="#8B3A0E" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={7.999}
          x2={7.999}
          y1={7.334}
          y2={10}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#191A33" />
          <stop offset={0.872} stopColor="#3B426A" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={8}
          x2={8}
          y1={4.934}
          y2={7.199}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E78E0D" />
          <stop offset={1} stopColor="#CB6000" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={13.5}
          x2={13.5}
          y1={15.05}
          y2={11.692}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#35CAFC" />
          <stop offset={1} stopColor="#007EDB" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={13.5}
          x2={13.5}
          y1={11.528}
          y2={13.606}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6AE1FF" stopOpacity={0.287} />
          <stop offset={1} stopColor="#A8E3FF" stopOpacity={0.799} />
        </linearGradient>
        <filter
          id="d"
          width={8.801}
          height={2.666}
          x={3.599}
          y={7.334}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0411227 0 0 0 0 0.0430885 0 0 0 0 0.0922353 0 0 0 0.819684 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
const AngryIcon = (props) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      id="angry"
      {...props}
    >
      <path fill="#DE524C" d="M16 8A8 8 0 1 1-.001 8 8 8 0 0 1 16 8" />
      <path
        fill="#000"
        d="M5.2 13.551c0 .528 1.253.444 2.8.444 1.546 0 2.8.084 2.8-.444 0-.636-1.254-1.051-2.8-1.051-1.547 0-2.8.415-2.8 1.051Z"
        filter="url(#b)"
      />
      <path
        fill="#000"
        d="M5.2 13.551c0 .528 1.253.444 2.8.444 1.546 0 2.8.084 2.8-.444 0-.636-1.254-1.051-2.8-1.051-1.547 0-2.8.415-2.8 1.051Z"
      />
      <path
        fill="#000"
        d="M3.6 9.831c0-.79.538-1.43 1.2-1.43.663 0 1.2.64 1.2 1.43 0 .33-.093.633-.252.874a.527.527 0 0 1-.318.22c-.15.036-.373.075-.63.075s-.481-.039-.63-.075a.524.524 0 0 1-.318-.22 1.588 1.588 0 0 1-.252-.874Zm6.4 0c0-.79.537-1.43 1.2-1.43.662 0 1.2.64 1.2 1.43 0 .33-.094.633-.252.874a.524.524 0 0 1-.318.22c-.207.05-.418.075-.63.075-.257 0-.48-.039-.63-.075a.53.53 0 0 1-.32-.22 1.596 1.596 0 0 1-.25-.874Z"
      />
      <path
        fill="#000"
        d="M3.6 9.831c0-.79.538-1.43 1.2-1.43.663 0 1.2.64 1.2 1.43 0 .33-.093.633-.252.874a.527.527 0 0 1-.318.22c-.15.036-.373.075-.63.075s-.481-.039-.63-.075a.524.524 0 0 1-.318-.22 1.588 1.588 0 0 1-.252-.874Zm6.4 0c0-.79.537-1.43 1.2-1.43.662 0 1.2.64 1.2 1.43 0 .33-.094.633-.252.874a.524.524 0 0 1-.318.22c-.207.05-.418.075-.63.075-.257 0-.48-.039-.63-.075a.53.53 0 0 1-.32-.22 1.596 1.596 0 0 1-.25-.874Z"
        filter="url(#e)"
      />
      <path
        fill="#E7B549"
        d="M4.968 9.333a.33.33 0 0 1 .007.07c0 .202-.176.367-.394.367-.217 0-.393-.165-.393-.366 0-.083.03-.16.08-.221.224.053.46.104.7.15Zm5.927.437c-.211 0-.383-.153-.393-.348.258-.038.515-.085.765-.136.014.038.021.078.02.119 0 .2-.175.365-.393.365Z"
      />
      <path
        fill="#000"
        d="M9 7.6c0-.446.163-.6.445-.6.28 0 .414.276.506 1.066 1.128 0 3.038-.534 3.222-.534.178 0 .277.085.317.267.035.158-.023.308-.221.4-.621.287-2.443.935-3.984.935-.168 0-.285-.086-.285-.301V7.6Zm-2.951.466C6.14 7.276 6.275 7 6.555 7c.282 0 .445.154.445.6v1.233c0 .215-.117.301-.285.301-1.541 0-3.363-.648-3.984-.935-.198-.092-.256-.242-.221-.4.04-.182.14-.267.317-.267.184 0 2.094.534 3.222.534Z"
        filter="url(#f)"
      />
      <path
        fill="url(#g)"
        d="M9 7.6c0-.446.163-.6.445-.6.28 0 .414.276.506 1.066 1.128 0 3.038-.534 3.222-.534.178 0 .277.085.317.267.035.158-.023.308-.221.4-.621.287-2.443.935-3.984.935-.168 0-.285-.086-.285-.301V7.6Zm-2.951.466C6.14 7.276 6.275 7 6.555 7c.282 0 .445.154.445.6v1.233c0 .215-.117.301-.285.301-1.541 0-3.363-.648-3.984-.935-.198-.092-.256-.242-.221-.4.04-.182.14-.267.317-.267.184 0 2.094.534 3.222.534Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1={8}
          x2={8}
          y2={10.751}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E04300" />
          <stop offset={1} stopColor="#FFA320" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={8}
          x2={8}
          y1={12.703}
          y2={14}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3D0D00" />
          <stop offset={1} stopColor="#661C04" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={8}
          x2={8}
          y1={8.4}
          y2={11}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#191A33" />
          <stop offset={0.872} stopColor="#3B426A" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={11.615}
          x2={11.615}
          y1={9.333}
          y2={7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A2F00" />
          <stop offset={1} stopColor="#D44800" />
        </linearGradient>
        <filter
          id="b"
          width={7.6}
          height={3.5}
          x={4.2}
          y={12.5}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.509681 0 0 0 0 0 0 0 0 0.371207 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="e"
          width={8.8}
          height={2.6}
          x={3.6}
          y={8.4}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.0387428 0 0 0 0 0.0406183 0 0 0 0 0.0875053 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="f"
          width={11.199}
          height={2.834}
          x={2.4}
          y={7}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={0.6} />
          <feGaussianBlur stdDeviation={0.05} />
          <feColorMatrix values="0 0 0 0 0.565875 0 0 0 0 0.151272 0 0 0 0 0 0 0 0 0.15024 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );

const dataMock = [
    {title: 'Thích', icon: <IconLike/>, keyWord: 'LIKE'},
    {title: 'Haha', icon: <IconHaha/>,keyWord: 'HAHA'},
    {title: 'Yêu thích', icon: <IconLove/>,keyWord: 'LOVE'},
    {title: 'Wow', icon: <IconWow/>, keyWord: 'WOW'},
    {title: 'Buồn', icon: <SadIcon/>, keyWord: 'SAD'},
    {title: 'Phẫn nộ', icon: <AngryIcon/>, keyWord: 'ANGRY'},
]
const PostContainer = ({username, idPost,scopeType, time, content, image, avt, numberlike, numbercomment,...props}) => {
    const user = useSelector(state => state.mainReducer.user)
    const userAvt= 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0m5Cy4lXCbuyG54L0vuo3i5-ALavHe9KmhWA_wDM&s'
    const [comment, setComment] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const isReaction = props.userReaction;
    const reactionType = props.reaction;
    const socketContext = useContext(SocketContext);

    const isUserMe = user.id === props?.userCreate?.id;
  
    const handleReaction =(reactionType) => {
        if(isReaction&&isReaction === reactionType) {
          console.log("dele reaction");
          socketContext.emit("deleteReaction", {postId: idPost, userId: user.id, reactionType :reactionType} );
          return;
        } 
            socketContext.emit("createReaction", {postId: idPost, userId: user.id, reactionType :reactionType} );
    }

    const contentReaction = (
        <div className='flex' >
        {dataMock.map((item, index) => {
            return (
              <Tooltip key={index} title={item.title} placement="top">
                <span className='mr-2 transition-all cursor-pointer hover:scale-150' onClick={()=>handleReaction(item.keyWord)}>
                {item.icon}
                </span>
              </Tooltip>
            )
        })}
        </div>
      );
    return (
        <>
            <div className='flex flex-col p-5 mt-4 bg-white rounded-xl shadown-full-1'>
                <div className='flex justify-between'>
                    <div className='flex '>
                    <Avatar alt="Remy Sharp" src={props?.userCreate?.avatar ?BASE_URL + `/api/files/upload`+ props.userCreate.avatar:userAvt} />
                        <div className='flex flex-col ml-3'>
                            <div>
                            <Link to={isUserMe?'/personal':`/personal/${props?.userCreate?.id}`} className='text-sm font-medium hover:opacity-90'>{username}</Link>
                                {/* <span className='text-sm font-medium'>{username}</span> */}
                                <span className='ml-1'><CheckCircleIcon fontSize='12px' className='text-blue-500 '/></span>

                            </div>
                            <span className='text-xs font-light text-gray-main'>
                            {time}
                            {scopeType === 'PUBLIC' && <PublicIcon fontSize='8px' className='ml-2'/>}
                            {scopeType === 'FRIEND' && <PeopleAltIcon fontSize='8px' className='ml-2'/>}
                            {scopeType === 'PRIVATE' && <HttpsIcon fontSize='8px' className='ml-2'/>}
                            
                            </span>
                        </div>          
                    </div>
                    <div>
                    <Tooltip title='Tùy chọn'>
                        <MoreHorizIcon className='mr-3 cursor-pointer text-gray-main' />
                    </Tooltip>
                        <CloseIcon className='mr-3 cursor-pointer text-gray-main' />
                    </div>

                </div>
                <div className='mt-3'>
                    <span className='font-light name-long'>{content}</span>
                </div>
                {image?.length > 0 &&image.length <=4 &&
                    <ImageListMain image={image}/>
                }
                {image?.length >=5  &&
                    <ImageListMain image={image}/>
                }
                <div className='flex justify-between mt-2'>
                    <div className='flex'>
                        {reactionType?.map((item, index) => {
                            const isShow = item[1] > 0;
                            return (
                              isShow?(
                              <>
                                {item[0] === 'LIKE' && <IconLike className='mr-[3px]'/>}
                                {item[0] === 'HAHA' && <IconHaha className='mr-[3px] text-yellow-600'/>}
                                {item[0] === 'LOVE' && <IconLove className='mr-[3px] text-red-600'/>}
                                {item[0] === 'WOW' && <IconWow className='mr-[3px] text-yellow-600'/>}
                                {item[0] === 'SAD' && <SadIcon className='mr-[3px] text-yellow-600'/>}
                                {item[0] === 'ANGRY' && <AngryIcon className='mr-[3px] text-red-600'/>}
                              </>):(<></>)
                               
                            );
                            
                        })}
                        <span className='text-xs font-light'>{numberlike}</span>
                    </div>
                    <div>
                        <span className='text-gray-main font-normal text-[14px]'>{numbercomment} Bình luận</span>
                        {/* <span className='text-gray-main font-normal text-[14px] ml-4'>{numbershare} Chia sẽ</span> */}
                    </div>

                </div>
                <div className='h-[2px] bg-slate-300 mt-2'></div>
                <div className='flex mt-3 '>
                        <Popover content={contentReaction} >
                            <div onClick={()=>handleReaction('LIKE')} className={`flex ${isReaction === 'LIKE' ?'text-blue-600 haha' :isReaction === 'HAHA'?'text-yellow-600':isReaction === 'LOVE' ?'text-red-600': isReaction === 'WOW'?' text-yellow-600':isReaction === 'SAD'?'text-yellow-600':isReaction === 'ANGRY'?'text-red-600':'text-gray-main'} justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer items-center  hover:bg-slate-200`}>
                                    {isReaction === 'LIKE' ? <IconLike/> :isReaction === 'HAHA'?<IconHaha/>:isReaction === 'LOVE' ? <IconLove/>: isReaction === 'WOW'?<IconWow/>:isReaction === 'SAD'?<SadIcon/>:isReaction === 'ANGRY'?<AngryIcon/>: <ThumbUpOffAltIcon/>}
                                    <span className='ml-3 '>
                                    {`${isReaction === 'LIKE' ?'Thích' :isReaction === 'HAHA'?'HAHA':isReaction === 'LOVE' ?'Yêu thích': isReaction === 'WOW'?'Wow':isReaction === 'SAD'?'Buồn':isReaction === 'ANGRY'?'Phẫn nộ':'Thích'}`}
                                    </span>
                            </div>
                        </Popover>
                    <div onClick={handleOpen} className='flex justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer text-gray-main hover:bg-slate-200'>
                        <CommentIcon/>
                        <span className='ml-3'>Bình luận</span>
                    </div>
                    <div className='flex justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer text-gray-main hover:bg-slate-200'>
                        <ReplyIcon/>
                        <span className='ml-3'>Chia sẻ</span>
                    </div>
                </div>
            {comment && <Comments idPost={idPost}/>}
            </div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='outline-none'
            >
            <Box sx={style} className='outline-none h-[80vh] overflow-y-auto have-y-scroll w-[60vw] rounded-lg p=[3px]'>
                <div className='flex flex-col p-5 mt-4 bg-white rounded-xl shadown-full-1'>
                    <div className='flex justify-between'>
                        <div className='flex '>
                        <Avatar alt="Remy Sharp" src={props?.userCreate?.avatar ?BASE_URL + `/api/files/upload`+ props.userCreate.avatar:userAvt} />
                            <div className='flex flex-col ml-3'>
                                <div>
                                    <span className='text-sm font-medium'>{username}</span>
                                    <span className='ml-1'><CheckCircleIcon fontSize='12px' className='text-blue-500 '/></span>

                                </div>
                                <span className='text-xs font-light text-gray-main'>
                                {time}
                                {scopeType === 'PUBLIC' && <PublicIcon fontSize='8px' className='ml-2'/>}
                                {scopeType === 'FRIEND' && <PeopleAltIcon fontSize='8px' className='ml-2'/>}
                                {scopeType === 'PRIVATE' && <HttpsIcon fontSize='8px' className='ml-2'/>}
                                
                                </span>
                            </div>          
                        </div>
                        <div>
                        <Tooltip title='Tùy chọn'>
                            <MoreHorizIcon className='mr-3 cursor-pointer text-gray-main' />
                        </Tooltip>
                            <CloseIcon onClick={handleClose} className='mr-3 cursor-pointer text-gray-main' />
                        </div>

                    </div>
                    <div className='mt-3'>
                        <span className='font-light name-long'>{content}</span>
                    </div>
                    {image?.length > 0 &&image.length <=4 &&
                        <ImageListMain image={image}/>
                    }
                    {image?.length >=5  &&
                        <ImageListMain image={image}/>
                    }
                    <div className='flex justify-between mt-2'>
                    <div className='flex'>
                        {reactionType?.map((item, index) => {
                            const isShow = item[1] > 0;
                            return (
                              isShow?(
                              <>
                                {item[0] === 'LIKE' && <IconLike className='mr-[3px]'/>}
                                {item[0] === 'HAHA' && <IconHaha className='mr-[3px] text-yellow-600'/>}
                                {item[0] === 'LOVE' && <IconLove className='mr-[3px] text-red-600'/>}
                                {item[0] === 'WOW' && <IconWow className='mr-[3px] text-yellow-600'/>}
                                {item[0] === 'SAD' && <SadIcon className='mr-[3px] text-yellow-600'/>}
                                {item[0] === 'ANGRY' && <AngryIcon className='mr-[3px] text-red-600'/>}
                              </>):(<></>)
                               
                            );
                            
                        })}
                        <span className='text-xs font-light'>{numberlike}</span>
                    </div>
                        <div>
                            <span className='text-gray-main font-normal text-[14px]'>{numbercomment} Bình luận</span>
                            {/* <span className='text-gray-main font-normal text-[14px] ml-4'>{numbershare} Chia sẽ</span> */}
                        </div>

                    </div>
                    <div className='h-[2px] bg-slate-300 mt-2'></div>
                    <div className='flex mt-3 '>
                        <Popover content={contentReaction} >
                            <div onClick={()=> handleReaction('LIKE')} className={`flex ${isReaction === 'LIKE' ?'text-blue-600 haha' :isReaction === 'HAHA'?'text-yellow-600':isReaction === 'LOVE' ?'text-red-600': isReaction === 'WOW'?' text-yellow-600':isReaction === 'SAD'?'text-yellow-600':isReaction === 'ANGRY'?'text-red-600':'text-gray-main'} justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer items-center  hover:bg-slate-200`}>
                                    {isReaction === 'LIKE' ? <IconLike/> :isReaction === 'HAHA'?<IconHaha/>:isReaction === 'LOVE' ? <IconLove/>: isReaction === 'WOW'?<IconWow/>:isReaction === 'SAD'?<SadIcon/>:isReaction === 'ANGRY'?<AngryIcon/>: <ThumbUpOffAltIcon/>}
                                    <span className='ml-3 '>
                                    {`${isReaction === 'LIKE' ?'Thích' :isReaction === 'HAHA'?'HAHA':isReaction === 'LOVE' ?'Yêu thích': isReaction === 'WOW'?'Wow':isReaction === 'SAD'?'Buồn':isReaction === 'ANGRY'?'Phẫn nộ':'Thích'}`}
                                    </span>
                            </div>
                        </Popover>
                        <div onClick={handleOpen} className='flex justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer text-gray-main hover:bg-slate-200'>
                            <CommentIcon/>
                            <span className='ml-3'>Bình luận</span>
                        </div>
                        <div className='flex justify-center flex-1 px-3 py-1 rounded-lg cursor-pointer text-gray-main hover:bg-slate-200'>
                            <ReplyIcon/>
                            <span className='ml-3'>Chia sẽ</span>
                        </div>
                    </div>
                <Comments idPost={idPost}/>
                </div>
            </Box>
        </Modal>
        </>
    );
};

export default PostContainer;