import  {all} from 'redux-saga/effects';
import { watcherAuth } from '../controllers/watchers/watcherAuth';
import { watcherMain } from '../controllers/watchers/watcherMain';

export default function* rootSaga() {
    yield all([
        watcherAuth(),
        watcherMain()
    ]);

}