import React from "react";
import { useController } from "react-hook-form";

const InputHook = ({ control, ...props }) => {
  const { field } = useController({
    control,
    name: props.name,
    defaultValue: "",
  });
  return (
    <input
    autoComplete="false"
      type="text"
      className="p-3 transition-all   bg-transparent text-white border-b-2 border-white placeholder:text-[#E8E8E8] outline-none focus:"
      {...field}
      {...props}
    />
  );
};

export default InputHook;
