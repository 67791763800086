import { Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search';
import * as ActionType from '../controllers/ActionType';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { deepOrange, deepPurple } from '@mui/material/colors';
import useDebounce from '../components/hooks/useDebounce';
import { Link } from 'react-router-dom';
import { Button, Popover } from 'antd';


function SearchHeader() {
    const dispatch = useDispatch()
    const [result, setResult] = React.useState([])
    const [keyword, setKeyWork] = React.useState('');
    const [isSearching, setIsSearching] = useState(false);
    const debouncedSearchTerm = useDebounce(keyword, 500);


    const handleSearch =(e)=>{ 
      setKeyWork(e.target.value)
    }

    // Effect for API call
      useEffect(
        () => {
          if (debouncedSearchTerm) {
            setIsSearching(true);
            // if(keyword == ''){
            //   setResult([])
            //   return;
            // }
            dispatch({type: ActionType.API_SEARCH, payload:keyword, setResult})
          } else {
            setResult([]);
            setIsSearching(false);
          }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
      );
      const listSearch=()=>{
        return(
            <Box sx={{color:'#000', backgroundColor:'white', maxWidth:'400px', width:'670px', position:'absolute', top:'120%', padding:'31px 10px 10px 24px', borderRadius:'6px', border:'1px solid #ccc'  }}>
                {result.length > 0 ?
                  <Grid container spacing={2} >
                  {result.map((item, index) => (
                    <Link className='w-full' key={index} to={`/personal/${item.id}`} >
                        <Grid item xs={12}  >
                          <Box sx={{display:'flex', width:'full', alignItems:'center', padding:'2px 4px', cursor:'pointer'}} className='hover:bg-slate-200'>
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>A</Avatar>
                            <Typography sx={{marginLeft:'8px'}}>{item.fullName}</Typography>
                          </Box>
                        </Grid>
                    </Link>
                  ))}
                  </Grid>
                  :
                  <Grid container spacing={2} >
                    <Typography sx={{marginLeft:'8px', marginTop:'8px'}}>Searchching...</Typography>
                  </Grid>}
            </Box>
        )
      }
  return (
    <Box className='relative'>
     <Popover placement="Bottom" title={'Search'} content={listSearch} trigger="click">
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField
              className='field-search'
              id="outlined-required"
              onChange={(e) => handleSearch(e)}
              // onChange={(e)=>{dispatch({type: ActionType.UPDATE_SEARCH, payload:e.target.value})}}
              label="Search"
              variant="standard"
            />
          </Box>

     </Popover>
  
      


    </Box>
  )
}

export default SearchHeader