import React from 'react';
import { Avatar } from '@mui/material';

const NotificationItem = ({onClick, isNotSeen, avt, content}) => {
    return (
        <div onClick={onClick} className=' flex items-center  cursor-pointer hover:bg-slate-100 rounded-lg  py-2'>
        <div>
        <Avatar alt="Remy Sharp" src={avt} />
        </div>
        <div className=' ml-3 flex flex-col'>
            <h3 className={`text-[050505] text-base ${isNotSeen ? 'font-semibold' :''} `}>{content}</h3>
        </div>
    </div>
    );
};

export default NotificationItem;