import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MovieIcon from '@mui/icons-material/Movie';
import AddIcon from '@mui/icons-material/Add';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./stories.scss"


const data =[
  {
    id:1,
    name:'Sơn  Tùng',
    img:'https://i.pinimg.com/236x/8e/34/e3/8e34e3e4a570228fecaf7ce3c81328f0.jpg'
  },
  {
    id:2,
    name:'Rose',
    img:'https://img6.thuthuatphanmem.vn/uploads/2022/04/16/anh-blackpink-rose_042753244.jpg'
  },
  {
    id:3,
    name:'Ronaldinho',
    img:'https://i.guim.co.uk/img/media/c5eb23c77cc79f67a6f6064f0726bcc614327b30/0_0_3300_1979/master/3300.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=c328454560966d5d110daf03eb78e538'
  },
  {
    id:4,
    name:'Leo messi',
    img:'https://kenh14cdn.com/203336854389633024/2022/12/19/worldcupmessi-16714134707591286548462.jpeg'
  },
  {
    id:5,
    name:'Jennie',
    img:'https://toigingiuvedep.vn/wp-content/uploads/2021/09/hinh-anh-jennie-blackpink-ca-tinh.jpg'
  },
  {
    id:6,
    name:'Lisa',
    img:'https://img.hoidap247.com/picture/question/20210221/large_1613874864044.jpg'
  },
  {
    id:8,
    name:'G-Dragon',
    img:'https://media.thieunien.vn/upload/oldmedia/thumb/img.news.large/2021/03/25/g-dragon-bat-ngo-xoa-avatar-va-anh-bia-tren-fb-ca-nhan-anh-dang-an-y-gi_1616669225.jpg'
  },
  {
    id:7,
    name:'Jisoo',
    img:'https://i.pinimg.com/originals/86/c3/e4/86c3e4b96b6c6d8e83b6a666abe77fed.jpg'
  },
  {
    id:8,
    name:'Lisa',
    img:'https://img.hoidap247.com/picture/question/20210221/large_1613874864044.jpg'
  },
  {
    id:9,
    name:'G-Dragon',
    img:'https://media.thieunien.vn/upload/oldmedia/thumb/img.news.large/2021/03/25/g-dragon-bat-ngo-xoa-avatar-va-anh-bia-tren-fb-ca-nhan-anh-dang-an-y-gi_1616669225.jpg'
  },
  {
    id:10,
    name:'Jisoo',
    img:'https://i.pinimg.com/originals/86/c3/e4/86c3e4b96b6c6d8e83b6a666abe77fed.jpg'
  },
 
]



const StoriesItem = () => {
    return (
      <Swiper grabCursor="true" slidesPerView={"auto"} className='swiper_container '>
           <SwiperSlide  className='create-stories-main'>
              <img className="h-[200px]"   src='https://cdn.landesa.org/wp-content/uploads/default-user-image.png' />
              <div className="flex flex-col create-stories-main-text">
              <AddIcon className="w-[30px] h-[30px] bg-blue-800 border-4 text-white border-white rounded-full "/>
              <span >Tạo tin</span>
              </div>
          </SwiperSlide>

          {data.length >0 && data.map((item) => (
            <SwiperSlide key={item.id} className='storie-item-main'>
              <img  src={item.img} alt='img stories' className="storie-item-main-img" />
              <span className="storie-item-main-name">{item.name}</span>
              <img className="storie-item-main-avt w-[40px] h-[40px] rounded-full border-2 border-blue-600" src={item.img} alt='avata'/>
          </SwiperSlide>
          ))}
      </Swiper>


)}

const Stories = () => {
    return (
        <div className="flex space-x-5 ">
            <section className="banner mr-[404px] h-[262px] p-3 bg-white page-container rounded-lg mb-1 overflow-hidden shadown-full-1">
              <div className="flex items-center mb-1 ">
                <div className="flex pb-2 items-center cursor-pointer mb-1 text-[#1876f2] flex-1 justify-center border-b-2 border-blue-700 ">
                  <AutoStoriesIcon/>
                  <h2 className="ml-1 font-medium ">Tin</h2>
                </div>
                <div className="flex pb-2 items-center cursor-pointer mb-1 text-[#65676b] flex-1 justify-center ">
                  <MovieIcon/>
                  <h2 className="ml-1 font-medium ">Reels</h2>
                </div>
              </div>
              <StoriesItem/>
            </section>
          <div className="fixed w-[390px] right-0 top-[87px]  z-10 flex-1">
            <h2 className="text-[#65657b] font-medium">Được tài trợ</h2>
            <div className="flex items-center mt-1 transition-all cursor-pointer hover:bg-slate-200">
              <img className="w-[110px] h-[110px] rounded-lg object-cover"  src='https://images.unsplash.com/photo-1607853202273-797f1c22a38e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cHM1fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60' alt='anh da bi loi'/>
              <div className="ml-3">
                <h3 className="text-[#050505] text-[15px]">Mua ngay siêu phẩm Ps5</h3>
                <span className="text-[#65676b] text-[13px] lowercase" >Gearvn.com</span>
              </div>
            </div>
            <div className="flex items-center mt-1 transition-all cursor-pointer hover:bg-slate-200">
              <img className="w-[110px] h-[110px] rounded-lg object-cover"  src='https://images.unsplash.com/photo-1591637333184-19aa84b3e01f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8bW90b3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60' alt='anh da bi loi'/>
              <div className="ml-3">
                <h3 className="text-[#050505] text-[15px]">Siêu phẩm đã có mặt tại Việt Nam</h3>
                <span className="text-[#65676b] text-[13px] lowercase" >motobike.com.vn</span>
              </div>
            </div>
            <div className="w-full h-[2px] mt-2 bg-[#cbd5e1]"></div>

          </div>

        </div>
    );
};

export default Stories;