import React from 'react';
import MessengerBody from '../components/messenger/MessengerBody';
import Header from '../Header/Header';

const MessagePage = () => {
    return (
        <div className='bg-slate-100'>
            <Header/>
            <MessengerBody/>
        </div>
    );
};

export default MessagePage;