import React, { useEffect } from "react";
import BodyHome from "../components/bodyhome/BodyHome";
import Stories from "../components/stories/Stories";
import ScrollButton from "../components/button/ScrollButton";
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../controllers/ActionType";
import { LoadingContext } from "../context/loadingContext";


export default function Home() {
  const dispatch = useDispatch();
  const {isLoading , setIsLoading} = React.useContext(LoadingContext);

  const getPost = () => {
      setIsLoading(true);
      dispatch({ type: ActionTypes.API_GET_POST, setIsLoading, payload: {} });
  };
  const getUser = () => {
      setIsLoading(true);
      dispatch({ type: ActionTypes.API_GET_USER, setIsLoading, payload: {} });
  };
  useEffect(() => {
      console.log("useEffect Home", process.env);
      getPost();
      getUser();
  }, []);

  return (
      <div className="relative">
          <Stories />
          <BodyHome />
          <ScrollButton />
      </div>
  );
}
