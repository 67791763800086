
import socialAuthReducer from '../model/authReducer';
import mainReducer from '../model/mainReducer';
let reducers = {
    socialAuthReducer,
    mainReducer
};


export default reducers;
